import React from 'react'
import Header from 'Components/Header';
import Footer from "Components/Footer";
import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';


import useSignUp from './useSignUp';
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';


const useStyles = makeStyles((theme) => ({
    content: {
        padding: '50px',
        minHeight: "70vh"
    },
    form: {
        padding: theme.spacing(3),


    },
    leftForm: {
        marginRight: theme.spacing(3)
    }
}));

export default function SignUpPage() {
    const classes = useStyles();
    const {
        user,
        signIn,
        setUserValues,
        userValidation,
        repeatPassword,
        setRepeatPassword } = useSignUp();

    return (

        <>
            <Header />
            <Container className={classes.content} maxWidth="xl">
                <form noValidate autoComplete="on" onSubmit={(e) => { e.preventDefault(); signIn() }}>
                    <Grid container direction="row" spacing={5} >

                        <Grid item container xs={12} md={6}>
                            <Box boxShadow={4} width="100%" padding="10px">
                                <Grid item container xs={12} spacing={3} className={classes.form}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            Datos de la Cuenta
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            autoFocus
                                            label="Correo Electrónico"
                                            error={inputHasError(userValidation.Email)}
                                            helperText={createInputErrorMessage(userValidation.Email)}
                                            variant="outlined"
                                            name="Email"
                                            value={user.Email}
                                            onChange={setUserValues}
                                            fullWidth />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            label="Contraseña"
                                            type="password"
                                            value={user.Password}
                                            name="Password"
                                            variant="outlined"
                                            onChange={setUserValues}
                                            fullWidth
                                            error={inputHasError(userValidation.Password)}
                                            helperText={createInputErrorMessage(userValidation.Password)} />
                                    </Grid>
                                    <Grid item md={12}>

                                        <TextField
                                            label="Repetir Contraseña"
                                            type="password"
                                            variant="outlined"
                                            fullWidth
                                            value={repeatPassword}
                                            onChange={(e) => setRepeatPassword(e.target.value)} />
                                    </Grid>

                                </Grid>

                            </Box>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Box boxShadow={4} width="100%">
                                <Grid item container xs={12} spacing={3} className={classes.form}>
                                    <Grid item xs={12} >
                                        <Typography variant="h5">
                                            Datos del Contacto
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            label="Nombre"
                                            variant="outlined"
                                            value={user.FirstName}
                                            name="FirstName"
                                            onChange={setUserValues}
                                            fullWidth
                                            error={inputHasError(userValidation.FirstName)}
                                            helperText={createInputErrorMessage(userValidation.FirstName)} />

                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            label="Apellido"
                                            variant="outlined"
                                            value={user.LastName}
                                            name="LastName"
                                            onChange={setUserValues}
                                            fullWidth
                                            error={inputHasError(userValidation.LastName)}
                                            helperText={createInputErrorMessage(userValidation.LastName)} />
                                    </Grid>
                                    <Grid item md={12}>

                                        <TextField
                                            label="CI/NIT"
                                            variant="outlined"
                                            value={user.CI}
                                            name="CI"
                                            onChange={setUserValues}
                                            fullWidth
                                            error={inputHasError(userValidation.CI)}
                                            helperText={createInputErrorMessage(userValidation.CI)} />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            label="Teléfono"
                                            variant="outlined"
                                            value={user.PhoneNumber}
                                            name="PhoneNumber"
                                            onChange={setUserValues}
                                            fullWidth
                                            error={inputHasError(userValidation.PhoneNumber)}
                                            helperText={createInputErrorMessage(userValidation.PhoneNumber)} />

                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl component="fieldset" error={inputHasError(userValidation.AccountType)} >
                                            <FormLabel component="legend">Tipo de Cuenta</FormLabel>
                                            <RadioGroup aria-label="quiz" name="AccountType" value={user.AccountType} onChange={setUserValues}>
                                                <FormControlLabel value="PERSONAL" control={<Radio />} label="Personal" />
                                                <FormControlLabel value="EMPRESARIAL" control={<Radio />} label="Empresarial" />
                                            </RadioGroup>
                                            <FormHelperText>{createInputErrorMessage(userValidation.AccountType)}</FormHelperText>

                                        </FormControl>

                                    </Grid>
                                    <Grid item md={12}>
                                        <Button variant="contained" color="secondary" type="submit" fullWidth>Registrarse</Button>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>

                    </Grid >
                </form>
            </Container>
            <Footer />
        </>
    )
}
