import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    footer: {
        position: "relative",
        left: 0,
        bottom: 0,
        minHeight: '30vh',
        background: theme.palette.primary.main,
        padding: theme.spacing(5)
    },
    menuButton: {
        marginRight: theme.spacing(1),

    },
    text: {
        color: 'white'
    }
}));

export default function Footer() {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div style={{marginTop:"auto"}}>
            <Grid container direction="column" className={classes.footer} justify="space-between">
                <Grid item>
                    <Button color="secondary" className={classes.menuButton} onClick={() => history.push("/")}>Inicio</Button>
                    <Button color="secondary" className={classes.menuButton}onClick={() => history.push("precios")}>Precio</Button>
                    <Button color="secondary" className={classes.menuButton} onClick={()=>history.push("contacto")}>Contacto</Button>
                    <Button color="secondary" className={classes.menuButton} onClick={() => history.push("login")}>Log In</Button>
                    <Button color="secondary" className={classes.menuButton} onClick={() => history.push("signin")}>Registrarse</Button>
                </Grid>
                <Grid item container justify="center" alignItems="center">
                    <Typography variant="h6" className={classes.text}>
                        Trovami, © 2021. Todos los derechos reservados
                </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
