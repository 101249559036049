import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router';

interface IProps {
    open: boolean;
    onCloseCallback: () => void;
}

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});


function LeftDrawer(props: IProps) {
    const history = useHistory();
    const styles = useStyles();
    return (
        <div>
            <Drawer open={props.open} onClose={props.onCloseCallback} anchor="left">
                <div className={styles.list}>
                    <List>

                        <ListItem button onClick={()=>history.push("/")}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Inicio" />
                        </ListItem>
                        <ListItem button onClick={()=>history.push("precios")}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Precio" />
                        </ListItem>
                        <ListItem button onClick={()=>history.push("contacto")}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Contacto" />
                        </ListItem>
                        <ListItem button onClick={()=>history.push("login")}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Log In" />
                        </ListItem>
                        <ListItem button onClick={()=>history.push("signin")}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Registrarse" />
                        </ListItem>

                    </List>
                </div>
            </Drawer>
        </div>
    )
}

export default LeftDrawer
