import { Container, Grid, Box, Typography, TextField, Button, makeStyles } from '@material-ui/core'
import useHttpRequest from 'Hooks/useHttpRequest';
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper'
import { Contact, CreateEmptyContact } from 'Models/Contact/Contact';
import { ContactValidation, CreateEmptyContactValidation } from 'Models/Contact/ContactValidation';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '50px'
    },
    principalGrid: {
        height: "100%"
    }
}))

interface IProps {
    email?: string
}

function ContactForm(props: IProps) {

    const classes = useStyles();
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [contact, setContact] = useState<Contact>(CreateEmptyContact());
    const [contactValidation, setContactValidation] = useState<ContactValidation>(CreateEmptyContactValidation());


    const setContactValues = (e: any) => {
        const { name, value } = e.target;
        setContact(prevState => ({
            ...prevState,
            [name]: value
        }))
    }


    const CreateContact = async () => {
        await executeHttpRequest({
            endpoint: "contact",
            method: "POST",
            body: contact,
            onSuccess: () => {
                enqueueSnackbar("Mensaje enviado correctamente", { variant: "success" });
                setContact(CreateEmptyContact());
                setContactValidation(CreateEmptyContactValidation());
            },
            onValidationError: (response) => {
                setContactValidation(response)
            }
        });
    }

    useEffect(() => {
        if (props.email)
            setContactValues({ target: { name: "Email", value: props.email } })
    }, [props.email])

    return (
        <div>
            <Container className={classes.content} maxWidth="xl">
                <Grid container direction="row" justify="center" alignItems="center" className={classes.principalGrid} >

                    <Grid item xs={12}>
                        <Box boxShadow={4} width="100%">
                            <Grid item container direction="column" spacing={3} style={{ padding: "1.5rem" }}>
                                <Grid item>
                                    <Typography variant="h5">
                                        Contactate con nosotros
                                    </Typography>
                                </Grid>
                                {!props.email  &&
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Correo electrónico"
                                            helperText={createInputErrorMessage(contactValidation.Email)}
                                            variant="outlined"
                                            name="Email"
                                            value={contact.Email}
                                            onChange={setContactValues}
                                            error={inputHasError(contactValidation.Email)}
                                            fullWidth />
                                    </Grid>}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Mensaje"
                                        helperText={createInputErrorMessage(contactValidation.Message)}
                                        variant="outlined"
                                        name="Message"
                                        value={contact.Message}
                                        error={inputHasError(contactValidation.Message)}
                                        type="password"
                                        multiline
                                        rows={7}
                                        onChange={setContactValues}
                                        fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => CreateContact()}
                                        fullWidth >
                                        Enviar Mensaje
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default ContactForm
