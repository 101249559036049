export interface UserValidation {
    Email?: string[],
    FirstName?: string[],
    LastName?: string[],
    Password?: string[],
    PhoneNumber?: string[],
    CI?: string[],
    AccountType: string[]

}


export const CreateEmptyyUserValidation = () => (
    {
        Email: [],
        FirstName: [],
        LastName: [],
        Password: [],
        PhoneNumber: [],
        CI: [],
        AccountType: []
    } as UserValidation)
