import { API_URL } from "Api/ApiConfig";
import { useAppState } from "Context/AppContext";
import { HttpParams } from "Models/Http/HttpParams";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useHttpRequest = () => {
    const { setLoading } = useAppState();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const executeHttpRequest = useCallback(async ({ endpoint, method, body, withToken = false, withMultipart = false, onSuccess, onValidationError, onError }: HttpParams) => {
        try {
            let data;
            setLoading(true);
            const token = localStorage.getItem('token');
            const reqOptions = {
                headers: { 'Authorization': "default", "Content-Type": "application/json" } as any,
                method,
                body: JSON.stringify(body)
            }

            if (withToken)
                reqOptions.headers["Authorization"] = `Bearer ${token}`;

            if (withMultipart) {
                delete reqOptions.headers["Content-Type"]
                reqOptions.body = body
            }

            const response = await fetch(`${API_URL}/${endpoint}`, reqOptions);

            setLoading(false);
            switch (response.status) {
                case 200:
                    data = await response.json();
                    if (onSuccess)
                        onSuccess(data);
                    else console.error("Callback not implemented")

                    break;
                case 201:
                    data = await response.json();
                    if (onSuccess)
                        onSuccess(data);
                    else console.error("Callback not implemented")

                    break;

                case 400:
                    data = await response.json();
                    let message = data.message;
                    let validation = {} as any;
                    message.forEach((error: any) => {
                        const aux: string[] = [];
                        for (const message in error.constraints) {
                            aux.push(error.constraints[message]);
                        }
                        (validation as any)[error.property] = aux;

                    });
                    enqueueSnackbar("Campos Incorrectos", { variant: "warning" });
                    if (onValidationError)
                        onValidationError(validation)
                    else console.error("Callback not implemented");
                    break;

                case 401:
                    enqueueSnackbar("Sesión expirada, vuelva a iniciar sesión", { variant: "warning" })
                    localStorage.clear();
                    history.push("/login");
                    break;
                case 429:
                    enqueueSnackbar("Espere 1 minuto antes de volver a realizar esta acción", { variant: "warning" })
                    break;

                case 500:
                    enqueueSnackbar("Error de servidor", { variant: "error" });
                    break;

                default:
                    data = await response.json();
                    enqueueSnackbar(data.message, { variant: "error" });
                    if (onError)
                        onError(data);

                    break;
            }
        } catch (error) {
            console.log(error)
            enqueueSnackbar("Problemas con el servidor", { variant: "error" });
            setLoading(false);
        }
    }, [enqueueSnackbar, history, setLoading])

    return { executeHttpRequest }

}

export default useHttpRequest;