import useHttpRequest from "Hooks/useHttpRequest";
import { Style } from "Models/Styles/Style";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";



const useStyleContect = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [styles, setStyles] = useState<Style[]>([]);
    const [selectedStyle, setSelectedStyle] = useState<Style | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const { executeHttpRequest } = useHttpRequest();

    const GetStylesAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "style",
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setStyles(data);
            }
        })
    }, [executeHttpRequest])

    const SelectStyle = useCallback((styleId: string) => {
        const style = styles.find(style => style.Id === styleId);
        if (style)
            setSelectedStyle(style);
        else
            setSelectedStyle(null);
    }, [styles])

    const DeleteStyleAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `style/${selectedStyle?.Id}`,
            method: "DELETE",
            withToken: true,
            onSuccess: async (data) => {
                enqueueSnackbar("Estilo borrado correctamente", { variant: "success" });
                await GetStylesAsync();
                setOpenDeleteDialog(false);
                setSelectedStyle(null);
            }
        })
    }, [GetStylesAsync, enqueueSnackbar, executeHttpRequest, selectedStyle?.Id])



    useEffect(() => {
        GetStylesAsync();
    }, [GetStylesAsync])

    return {
        selectedStyle,
        styles,
        SelectStyle,
        DeleteStyleAsync,
        openDeleteDialog,
        setOpenDeleteDialog,
        GetStylesAsync
    }

}

export default useStyleContect;