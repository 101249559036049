import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@material-ui/core'
import useHttpRequest from 'Hooks/useHttpRequest'
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react'
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';
import { SuscriptionValidation } from 'Models/Suscriptions/SuscriptionValidation';

interface IProps {
    OnCloseCallback: () => void,
    OnActionCallback: () => void,
    Open: boolean
}

function AddSuscriptionDialog(props: IProps) {

    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [numberCards, setNumberCards] = useState(1);
    const [suscriptionValidation, setSuscriptionValidation] = useState<SuscriptionValidation>({
        NumberCards: []
    })

    const ApplyForSuscription = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "suscription",
            method: "POST",
            withToken: true,
            body: { NumberCards: numberCards },
            onSuccess: () => {
                enqueueSnackbar("Solicitud Enviada Correctamente, la solicitud será atendida por un Administrador", { variant: "success" });
                props.OnActionCallback();
            },
            onValidationError: (response) => {
                setSuscriptionValidation(response);
            }
        })
    }, [executeHttpRequest, enqueueSnackbar, numberCards, props])


    return (
        <div>
            <Dialog open={props.Open} onClose={props.OnCloseCallback} fullWidth>
                <form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); ApplyForSuscription(); }}>
                    <DialogTitle>Solicitar Suscripción</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Por favor ingrese el ńumero de tarjetas que desea contratar
                    </DialogContentText>
                        <TextField
                            value={numberCards}
                            type="number"
                            onChange={(e) => setNumberCards(parseInt(e.target.value))}
                            autoFocus
                            margin="dense"
                            name="NumberCards"
                            label="Número de Tarjetas"
                            fullWidth
                            error={inputHasError(suscriptionValidation.NumberCards)}
                            helperText={createInputErrorMessage(suscriptionValidation.NumberCards)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.OnCloseCallback} color="primary">
                            Cancelar
                     </Button>
                        <Button color="primary" type="submit">
                            Enviar Solicitud
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default AddSuscriptionDialog
