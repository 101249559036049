import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useAppState } from 'Context/AppContext';
import useHttpRequest from 'Hooks/useHttpRequest';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router';
import Link from '@material-ui/core/Link';

interface IProps {
    OnCloseCallback: () => void,
    Open: boolean,
    UserEmail: string
}

function ConfirmationDialog(props: IProps) {

    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const { setUser } = useAppState();
    const history = useHistory();

    const [verficationCode, setVerificationCode] = useState<string>("");

    const getUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setUser(data)
            }
        })
    }, [executeHttpRequest, setUser])

    const VerifyUser = async () => {
        await executeHttpRequest({
            endpoint: "user/auth/confirmation",
            method: "PATCH",
            body: {
                VerificationCode: verficationCode,
                UserEmail: props.UserEmail
            },
            onSuccess: async (data) => {
                enqueueSnackbar("Bienvenido", { variant: "success" })
                localStorage.setItem("token", data.Token);
                await getUserInfo();
                history.push("/tarjetas")
            }
        })
    }

    const ResendVerificationCode = async () => {
        await executeHttpRequest({
            endpoint: "user/action/confirmationcode",
            method: "POST",
            body: {
                UserEmail: props.UserEmail
            },
            onSuccess: (data) => {
                enqueueSnackbar(data.message,{variant:"success"});
            }
        })
    }



    return (
        <div>
            <Dialog open={props.Open} onClose={props.OnCloseCallback} fullWidth>
                <DialogTitle>Confirmación de cuenta</DialogTitle>
                <DialogContent>
                    <TextField
                        value={verficationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        autoFocus
                        margin="dense"
                        name="Name"
                        label="Código de confirmación"
                        fullWidth
                        helperText={`El codigo fue enviado a ${props.UserEmail} revise su bandeja de entrada`}
                    />
                    <Link component="button" variant="body2" color="textPrimary" onClick={ResendVerificationCode}>Reenviar código</Link>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.OnCloseCallback} color="primary">
                        Cancelar
                     </Button>
                    <Button color="primary" onClick={VerifyUser}>
                        Confirmar Cuenta
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmationDialog
