import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette:{
        primary:{
            main: '#223242'
        },
        secondary: {
            main: '#b6e600'
        }
    }
});

export default theme;