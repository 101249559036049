import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        minHeight: '60vh',
        color: 'black'
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '2rem',
        textAlign: 'start',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'start',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
        display: "block",
        margin: 'auto'
    },
    leftContent: {
        padding: '3.5rem',
        paddingBottom:"0"

    },
    rightContent: {
    }
}));

export default function Section4() {
    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" className={classes.section1}>


            <Grid item container direction="column" xs={12} md={6} alignItems="flex-start" justify="center" className={classes.leftContent}>

                <Typography variant="h3" className={classes.title}>
                    QR dinámico e inclusivo
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Generamos QR dinámicos con acceso directo
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Ayudamos a ser inclusivos y a integrar los QR para conectar los soportes tradicionales
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Ayudamos al medioambiente a través de la reducción del uso del papel.
                </Typography>

            </Grid>
            <Grid item xs={12} md={6} className={classes.rightContent}>
                <img src="/section4.png" alt="" className={classes.image} width="100%" />
            </Grid>


        </Grid>


    );
}

