import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core'
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';
import { CreateEmtpyResetPassword, ResetPassword } from 'Models/ResetPassword/ResetPassword'
import { CreateEmtpyResetPasswordValidation, ResetPasswordValidation } from 'Models/ResetPassword/ResetPasswordValidations';
import React, { useEffect, useState } from 'react'
import { Link } from '@material-ui/core'
import useHttpRequest from 'Hooks/useHttpRequest';
import { useSnackbar } from 'notistack';


interface IProps {
    Open: boolean,
    OnCloseCallback: () => void,
    email?: string
}

function ResetPasswordDialog(props: IProps) {
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [resetPassword, setResetPassword] = useState<ResetPassword>(CreateEmtpyResetPassword());
    const [resetPasswordValidation, setResetPasswordValidation] = useState<ResetPasswordValidation>(CreateEmtpyResetPasswordValidation());

    const SetResetPasswordValues = (e: any) => {
        const { name, value } = e.target;
        setResetPassword(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const ResendVerificationCode = async () => {
        await executeHttpRequest({
            endpoint: "user/action/confirmationcode",
            method: "POST",
            body: {
                UserEmail: resetPassword.UserEmail
            },
            onSuccess: (data) => {
                enqueueSnackbar(data.message, { variant: "success" });
            }
        })
    }

    const ChangePassword = async () => {
        await executeHttpRequest({
            endpoint: "user/action/resetpassword",
            method: "POST",
            body: resetPassword,
            onValidationError: (data) => {
                setResetPasswordValidation(data)
            },
            onSuccess: (data) => {
                enqueueSnackbar(data.message, { variant: "success" });
                props.OnCloseCallback();
            }
        })
    }

    useEffect(() => {
        if (props.email) {
            SetResetPasswordValues({ target: { name: "UserEmail", value: props.email } })
        }
    }, [props.email])

    return (
        <div>
            <Dialog open={props.Open} onClose={props.OnCloseCallback} fullWidth>
                <DialogTitle>Cambiar Contraseña</DialogTitle>
                <DialogContent>
                    <TextField
                        value={resetPassword.UserEmail}
                        onChange={(e) => SetResetPasswordValues(e)}
                        error={inputHasError(resetPasswordValidation.UserEmail)}
                        autoFocus
                        margin="dense"
                        name="UserEmail"
                        disabled={props.email == null ? false : true}
                        label="Correo Eléctrónico"
                        fullWidth
                        helperText={createInputErrorMessage(resetPasswordValidation.UserEmail)}
                    />
                    <TextField
                        value={resetPassword.Password}
                        onChange={(e) => SetResetPasswordValues(e)}
                        error={inputHasError(resetPasswordValidation.Password)}
                        margin="dense"
                        name="Password"
                        type="password"
                        label="Nueva Contraseña"
                        fullWidth
                        helperText={createInputErrorMessage(resetPasswordValidation.Password)}
                    />
                    <TextField
                        value={resetPassword.VerificationCode}
                        onChange={(e) => SetResetPasswordValues(e)}
                        error={inputHasError(resetPasswordValidation.VerificationCode)}
                        margin="dense"
                        name="VerificationCode"
                        label="Código de Verificación"
                        fullWidth
                        helperText={createInputErrorMessage(resetPasswordValidation.VerificationCode)}
                    />
                    <Link component="button" variant="body2" color="textPrimary" onClick={ResendVerificationCode}>Enviar código de verificación</Link>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.OnCloseCallback} color="primary" >
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={ChangePassword}>
                        Cambiar Contraseña
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ResetPasswordDialog
