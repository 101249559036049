import { useAppState } from "Context/AppContext";
import useHttpRequest from "Hooks/useHttpRequest";
import { CreateEmptyUser, User } from "Models/User/User";
import { CreateEmptyyUserValidation, UserValidation } from "Models/User/UserValidation";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";


const useAccountContent = () => {
    const [user, setUserInfo] = useState<User>(CreateEmptyUser());
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    const [userValidation, setUserValidation] = useState<UserValidation>(CreateEmptyyUserValidation())

    const { setUser } = useAppState();

    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();


    const GetUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "GET",
            withToken: true,
            onSuccess: (user) => {
                setUserInfo(user);
            }
        })
    }, [executeHttpRequest])

    const UpdateUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "PUT",
            withToken: true,
            body: {
                FirstName: user.FirstName,
                LastName: user.LastName,
                CI: user.CI,
                PhoneNumber: user.PhoneNumber
            },
            onSuccess: async (data) => {
                enqueueSnackbar("Datos actualizados correctamente", { variant: "success" });
                await GetUserInfo();
                setUserValidation(CreateEmptyyUserValidation())
                setUser(user);
            },
            onValidationError: (validation) => {
                setUserValidation(validation)
            }
        })
    }, [setUser, executeHttpRequest, enqueueSnackbar, GetUserInfo, user])

    const setUserValues = (e: any) => {
        const { name, value } = e.target;
        setUserInfo(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        GetUserInfo();
    }, [GetUserInfo])

    return {
        user,
        showResetPasswordDialog,
        setShowResetPasswordDialog,
        UpdateUserInfo,
        setUserValues,
        userValidation
    }
}

export default useAccountContent;