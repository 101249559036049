import useHttpRequest from "Hooks/useHttpRequest";
import { AdminLogin, CreateEmptyAdminLogin } from "Models/Admin/Admin";
import { AdminLoginValidation, CreateEmptyAdminLoginValidation } from "Models/Admin/AdminValidation";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";


const useLogin = () => {
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [adminLogin, setAdminLogin] = useState<AdminLogin>(CreateEmptyAdminLogin());

    const [adminLoginValidation, setAdminLoginValidation] = useState<AdminLoginValidation>(CreateEmptyAdminLoginValidation())

    const signIn = async () => {

        await executeHttpRequest({
            endpoint: "admin/login",
            method: "POST",
            body: adminLogin,
            onSuccess: (data) => {
                enqueueSnackbar("Bienvenido", { variant: "success" })
                localStorage.setItem("token", data.TOKEN);
                history.push("/admin/mensajes")
            },
            onValidationError: (data) => {
                setAdminLoginValidation(data);
            }
        });
    }

    const setLoginValues = (e: any) => {
        const { name, value } = e.target;
        setAdminLogin(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    return { adminLogin, adminLoginValidation, signIn, setLoginValues }
}

export default useLogin;