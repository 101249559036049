import useHttpRequest from "Hooks/useHttpRequest";
import { Group } from "Models/Groups/Group";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";


interface RouteParams {
    groupId: string
}

const useCardPage = () => {
    const { groupId } = useParams<RouteParams>();
    const { executeHttpRequest } = useHttpRequest();
    const history = useHistory();
    const [group, setGroup] = useState<Group | null>(null);

    const getGroupAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `group/${groupId}`,
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setGroup(data);
            },
            onError: (data) => {
                history.push("/tarjetas");
            }
        })
    }, [groupId, executeHttpRequest, history])

    useEffect(() => {
        getGroupAsync();

    }, [getGroupAsync])

    return {
        group,
    }
}

export default useCardPage;