
export interface StyleValidation {
    Name: string[];
    ColorTheme: string[];
    IconColor: string[];
    Business: string[];
    Twitter: string[];
    Facebook: string[];
    Linkedin: string[];
    BusinessPage: string[];
    Video: string[];
}

export const CreateEmptyStyleValidation = () => ({
    Name: [],
    ColorTheme: [],
    IconColor: [],
    Business: [],
    Twitter: [],
    Facebook: [],
    Linkedin: [],
    BusinessPage: [],
    Video: []
} as StyleValidation)