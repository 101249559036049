
export interface Card {

    Id: string;
    Name: string;
    LastName: string;
    Position: string;
    Phone: string;
    WhatsApp: boolean;
    Email: string;
    GroupId: string;
    SuscriptionId: string;
    AvatarUrl: string;

    // Fields from another schemas 
    ColorTheme: string;
    IconColor: string;
    IconUrl: string;
    BannerUrl: string;
    BussinessPage: string;
    Business: string;
    Twitter: string;
    Facebook: string;
    Linkedin: string;
    Address: string;
    Video: string;
    UserId: string;
    ExpDate: Date;
}

export const CreateEmptyCard = () => ({
    Id: "",
    Name: "",
    LastName: "",
    Position: "",
    Phone: "",
    WhatsApp: false,
    Email: "",
    GroupId: "",
    SuscriptionId: "",
    AvatarUrl: "",
    ColorTheme: "",
    IconColor: "",
    IconUrl: "",
    BannerUrl: "",
    BussinessPage: "",
    Business: "",
    Twitter: "",
    Facebook: "",
    Linkedin: "",
    Address: "",
    Video: "",
    UserId: "",
    ExpDate: new Date()
} as Card)