import useHttpRequest from "Hooks/useHttpRequest";
import { Card } from "Models/Card/Card";
import { Group } from "Models/Groups/Group";
import { CreateEmtpyStyle, Style } from "Models/Styles/Style";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";



interface RouteParams {
    groupId: string;
}

const useCardContent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { groupId } = useParams<RouteParams>();
    const { executeHttpRequest } = useHttpRequest();
    const [cards, setCards] = useState<Card[]>([]);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [style, setStyle] = useState<Style>(CreateEmtpyStyle());

    const getCardsAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `group/${groupId}/card`,
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setCards(data);
            }
        })
    }, [groupId, executeHttpRequest])

    const deleteCardAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `card/${selectedCard?.Id}`,
            method: "DELETE",
            withToken: true,
            onSuccess: async (data) => {
                enqueueSnackbar("Carta borrada correctamente", { variant: "success" });
                await getCardsAsync()
                setOpenDeleteDialog(false);
                setSelectedCard(null);
            }
        })
    }, [enqueueSnackbar, executeHttpRequest, getCardsAsync, selectedCard?.Id])

    const selectCard = useCallback((cardId: string) => {
        const card = cards.find(card => card.Id === cardId);
        if (card) {
            setSelectedCard(card);
        } else {
            setSelectedCard(null);
        }
    }, [cards])

    const GetStyle = useCallback(async (styleId) => {
        await executeHttpRequest({
            endpoint: `style/${styleId}`,
            method: "GET",
            withToken: true,
            onSuccess: (style) => {
                setStyle(style);
            }
        })
    }, [executeHttpRequest])

    const GetGroup = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `group/${groupId}`,
            method: "GET",
            withToken: true,
            onSuccess: async (group: Group) => {
                await GetStyle(group.StyleId);
            }
        })
    }, [GetStyle, groupId, executeHttpRequest])





    const redirectToCard = useCallback(() => {

        history.push(`/tarjeta/${selectedCard?.Id}`);
    }, [history, selectedCard])

    useEffect(() => {
        getCardsAsync();
        GetGroup();
    }, [getCardsAsync, GetGroup])

    return {
        groupId,
        cards,
        selectedCard,
        openDeleteDialog,
        setOpenDeleteDialog,
        selectCard,
        deleteCardAsync,
        getCardsAsync,
        redirectToCard,
        style
    }



}

export default useCardContent;