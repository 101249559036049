import React from "react";
import { Grid, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        minHeight: '60vh',
        color: 'black',
        padding: '1rem'
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '2rem',
        textAlign: 'start',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'start',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
    },
    leftContent: {

    },
    rightContent: {

    }
}));

export default function Section2() {
    const classes = useStyles();

    return (
        <div>
            <Grid container justify="center" alignItems="center" className={classes.section1}>


                <Grid item container direction="column" xs={12} alignItems="center" md={6} className={classes.leftContent}>
                    <img src="/formats.png" alt="" width="65%" />


                </Grid>
                <Grid item xs={12} md={6} className={classes.rightContent} >
                    <img src="/section2.png" alt="" className={classes.image} width="100%" />
                </Grid>

            </Grid>
        </div>

    );
}

