import useHttpRequest from "Hooks/useHttpRequest";
import { Style } from "Models/Styles/Style";
import { CreateEmptyStyleValidation, StyleValidation } from "Models/Styles/StyleValidation";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

const useStyleDrawer = () => {
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [style, setStyle] = useState<Style>({
        Id: "",
        Address: "",
        BannerUrl: "",
        BusinessPage: "",
        Business: "",
        UserId: "",
        ColorTheme: "#a46565",
        Facebook: "",
        IconColor: "#dae811",
        IconUrl: "",
        Linkedin: "",
        Name: "",
        Twitter: "",
        Video: "",
    });
    const [styleValidation, setStyleValidation] = useState<StyleValidation>(CreateEmptyStyleValidation())
    const [iconFile, SetIconFile] = useState<File | null>(null);
    const [bannerFile, SetBannerFile] = useState<File | null>(null);

    const SetStyleValues = (e: any) => {
        const { name, value } = e.target;
        setStyle(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const SetSyleValuesByName = useCallback((name: any, value: any) => {
        setStyle(prevState => ({
            ...prevState,
            [name]: value
        }))
    }, [])

    const CreateStyleAsync = useCallback(async (callback: any) => {

        const formData = new FormData();

        formData.append("Name", style.Name);
        formData.append("ColorTheme", style.ColorTheme);
        formData.append("IconColor", style.IconColor);
        formData.append("BusinessPage", style.BusinessPage);
        formData.append("Business", style.Business);
        formData.append("Twitter", style.Twitter);
        formData.append("Facebook", style.Facebook);
        formData.append("Linkedin", style.Linkedin);
        formData.append("Address", style.Address);
        formData.append("Video", style.Video);

        if (iconFile) {
            formData.append("icon", iconFile);
        }
        if (bannerFile) {
            formData.append("background", bannerFile)
        }

        await executeHttpRequest({
            endpoint: "style",
            method: "POST",
            withToken: true,
            withMultipart: true,
            body: formData,
            onSuccess: async (data) => {
                enqueueSnackbar("Estilo Creado Correctamente", { variant: "success" });
                callback();
            },
            onValidationError: (response) => {
                setStyleValidation(response)
            }
        })
    }, [enqueueSnackbar, executeHttpRequest, style, bannerFile, iconFile])

    const UpdateStyleAsync = useCallback(async (callback: any) => {
        const formData = new FormData();

        formData.append("Name", style.Name);
        formData.append("ColorTheme", style.ColorTheme);
        formData.append("IconColor", style.IconColor);
        formData.append("BusinessPage", style.BusinessPage);
        formData.append("Business", style.Business);
        formData.append("Twitter", style.Twitter);
        formData.append("Facebook", style.Facebook);
        formData.append("Linkedin", style.Linkedin);
        formData.append("Address", style.Address);
        formData.append("Video", style.Video);

        if (iconFile) {
            formData.append("icon", iconFile);
        }
        if (bannerFile) {
            formData.append("background", bannerFile)
        }
        await executeHttpRequest({
            endpoint: `style/${style.Id}`,
            method: "PUT",
            withToken: true,
            body: formData,
            withMultipart: true,
            onSuccess: async (data) => {
                enqueueSnackbar("Estilo actualizado correctamente", { variant: "success" })
                callback();
            },
            onValidationError: (response) => {
                setStyleValidation(response)
            }
        })
    }, [executeHttpRequest, enqueueSnackbar, style, bannerFile, iconFile])



    return {
        style,
        setStyle,
        SetStyleValues,
        CreateStyleAsync,
        UpdateStyleAsync,
        styleValidation,
        SetSyleValuesByName,
        SetIconFile,
        SetBannerFile
    }

}

export default useStyleDrawer;