import { Grid } from '@material-ui/core'
import ContactForm from 'Components/ContactForm/ContactForm'
import Footer from 'Components/Footer'
import Header from 'Components/Header'
import React from 'react'


function ContactPage() {


    return (

        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <Header />
            <Grid container justify="center">
                <Grid item md={4} xs={12}>
                    <ContactForm />
                </Grid>

            </Grid>

            <Footer />
        </div>
    )
}

export default ContactPage
