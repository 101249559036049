import React from 'react'
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import { Box, Button, Container, Grid, Hidden, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import useLogin from './useLogin';
import { createInputErrorMessage } from 'lib/ValidatorHelper';
import ConfirmationDialog from './ConfirmationDialog';
import ResetPasswordDialog from './ResetPasswordDialog';



const useStyles = makeStyles((theme) => ({
    content: {
        padding: '50px'
    },
    principalGrid: {
        height: "100%"
    }
}))

export default function LoginPage() {
    const { login, signIn, setLoginValues, loginValidation, setShowConfirmationdialog, showConfirmationDialog, showResetPasswordDialog, setShowResetPasswordDialog } = useLogin();
    const classes = useStyles();


    return (
        <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <Header />
            <Container className={classes.content} maxWidth="xl">
                <Grid container direction="row" justify="center" alignItems="center" className={classes.principalGrid} >
                    <Hidden xsDown>
                        <Grid item xs={4}>

                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={4}>
                        <form noValidate autoComplete="on" onSubmit={(e) => { e.preventDefault(); signIn(); }}>
                            <Box boxShadow={4} width="100%">
                                <Grid item container direction="column" spacing={3} style={{ padding: "1.5rem" }}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            Iniciar Sesión
                            </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            label="Correo electrónico"
                                            helperText={createInputErrorMessage(loginValidation.Email)}
                                            variant="outlined"
                                            name="username"
                                            value={login.username}
                                            onChange={setLoginValues}
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Contraseña"
                                            helperText={createInputErrorMessage(loginValidation.Password)}
                                            variant="outlined"
                                            name="password"
                                            value={login.password}
                                            type="password"
                                            onChange={setLoginValues}
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            fullWidth >
                                            Iniciar Sesión
                                    </Button>
                                        <Link component="button" variant="body2" color="textPrimary" type="button" onClick={() => setShowResetPasswordDialog(true)} >Olvido su contaseña?</Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={4}>

                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
            {showConfirmationDialog && <ConfirmationDialog OnCloseCallback={() => setShowConfirmationdialog(false)} Open={showConfirmationDialog} UserEmail={login.username} />}
            {showResetPasswordDialog && <ResetPasswordDialog Open={showResetPasswordDialog} OnCloseCallback={() => setShowResetPasswordDialog(false)} />}
            <Footer />
        </div>
    )
}
