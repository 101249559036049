import { AppBar, Toolbar, IconButton, Button, makeStyles, Hidden, Grid } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import LeftDrawer from "./LeftDrawer";


const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(1),

    }
}));

export default function Header() {
    const classes = useStyles();
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar >

                    <Grid container direction="row" justify="space-between">

                        <Grid item xs={10} md container direction="row" alignItems="center">

                            <Hidden mdUp>
                                <IconButton edge="start" className={classes.menuButton} color="secondary" aria-label="menu" onClick={() => setOpenDialog(true)} >
                                    <Menu />
                                </IconButton>
                            </Hidden>

                        </Grid>
                        <Grid item xs={2}>
                            <Hidden mdUp>
                                <img src="/logo.jpg" alt="" width="100%" />

                            </Hidden>
                            <Hidden smDown>
                                <img src="/logo.jpg" alt="" width="40%" />
                            </Hidden>
                        </Grid>
                        <Grid item container xs md={10} justify="flex-end">
                            <Hidden smDown>
                                <Button color="secondary" className={classes.menuButton} onClick={() => history.push("/")}>Inicio</Button>
                                <Button color="secondary" className={classes.menuButton}onClick={() => history.push("precios")}>Precio</Button>
                                <Button color="secondary" className={classes.menuButton} onClick={() => history.push("contacto")}>Contacto</Button>
                                <Button color="secondary" variant="contained" className={classes.menuButton} onClick={() => history.push("login")} >Log In</Button>
                                <Button color="secondary" variant="contained" className={classes.menuButton} onClick={() => history.push("signin")}>Registrarse</Button>
                            </Hidden>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>
            <LeftDrawer open={openDialog} onCloseCallback={() => setOpenDialog(false)} />
        </>
    );
}