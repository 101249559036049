import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowsProp } from '@material-ui/data-grid';
import { Delete, PersonAdd, Update } from '@material-ui/icons';
import React, { useState } from 'react'
import StyleDrawer from './StyleDrawer/StyleDrawer';
import useStyleContect from './useStyleContent'

export default function StylesContent() {
    const { styles, selectedStyle, SelectStyle, GetStylesAsync, openDeleteDialog, setOpenDeleteDialog, DeleteStyleAsync } = useStyleContect();
    const [openDrawer, setOpenDrawer] = useState(false);

    const columns: GridColDef[] = [
        { field: "name", headerName: "Nombre", flex: 1 }
    ]

    const rows: GridRowsProp = styles.map(style => {
        return {
            id: style.Id,
            name: style.Name
        }
    });


    return (
        <div>
            <Grid container spacing={3}>
                <Grid container item xs={12} spacing={2}>

                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDrawer(true)} color="secondary" startIcon={<PersonAdd />} disabled={selectedStyle !== null} fullWidth>Agregar</Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDrawer(true)} color="primary" startIcon={<Update />} disabled={selectedStyle == null} fullWidth>Modificar</Button >

                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDeleteDialog(true)} color="default" startIcon={<Delete />} disabled={selectedStyle == null} fullWidth>Eliminar </Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button onClick={() => { SelectStyle("") }}
                            variant="contained" color="primary" startIcon={<Update />} disabled={selectedStyle == null} fullWidth>Deseleccionar</Button >

                    </Grid>

                </Grid>
                <Grid item xs={12} style={{ height: "50vh" }}>

                    <DataGrid columns={columns}
                        rows={rows} disableColumnMenu hideFooterSelectedRowCount
                        onRowSelected={(data) => { SelectStyle(data.data.id.toString()) }} scrollbarSize={12}
                    />

                </Grid>
            </Grid>


            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} fullWidth>
                <DialogTitle>Borrar Estilo: {selectedStyle?.Name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Esta seguro que desea eliminar este Estilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancelar
                     </Button>
                    <Button color="primary" onClick={() => { DeleteStyleAsync() }}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {openDrawer && <StyleDrawer closeCallback={async () => { setOpenDrawer(false); await GetStylesAsync(); SelectStyle(""); }} visible={openDrawer} style={selectedStyle} />}
        </div>
    )
}
