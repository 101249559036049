export interface Contact {
    Id: string;
    Email: string;
    Message: string;
    UserId?: string;
    DateSubmited: Date;
}

export const CreateEmptyContact = () => ({
    Id: "",
    Email: "",
    Message: "",
    UserId: "",
} as Contact)