
import { Button, Card, CardActions, CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import useHttpRequest from 'Hooks/useHttpRequest';
import { Group } from 'Models/Groups/Group';
import { Style } from 'Models/Styles/Style';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CreateUpdateGroupDialog from './CreateUpdateGroupDialog';
import DeleteGroupDialog from './DeleteGroupDialog';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,.1)"
        }
    },
    title: {
        textAlign: "center",
        marginBottom: theme.spacing(1)
    }
}));

export default function GroupsContent() {
    // Hooks
    const stylesCss = useStyles();
    const history = useHistory();
    const { executeHttpRequest } = useHttpRequest();

    // States
    const [groups, setGroups] = useState<Group[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<Group>({
        Id: "",
        Name: "",
        NumberCards: 0,
        StyleId: "",
        UserId: ""
    })
    const [styles, setStyles] = useState<Style[]>([]);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // API Methods
    const GetStylesAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "style",
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setStyles(data);
            }
        })
    }, [executeHttpRequest])

    const GetGroupsAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "group",
            method: "GET",
            withToken: true,
            onSuccess: (groups) => {
                setGroups(groups)
            },

        })
    }, [executeHttpRequest])

    // Helper Methods
    const SelectGroup = useCallback((groupId: string) => {
        if (groupId === "") {
            setSelectedGroup({
                Id: "",
                Name: "",
                NumberCards: 0,
                StyleId: "",
                UserId: ""
            })
        } else {
            const group = groups.find(g => g.Id === groupId);
            setSelectedGroup(group!);
        }
    }, [groups])

    const RedirectToGroup = (groupId: string) => {
        history.push(`/grupo/${groupId}/tarjetas`);
    }

    // Callback Methods
    const OnCloseCallback = useCallback(() => {
        setOpenCreateDialog(false)
        setOpenDeleteDialog(false)
        SelectGroup("")
    }, [setOpenCreateDialog, SelectGroup, setOpenDeleteDialog])

    const OnActionCallback = useCallback(async () => {
        setOpenCreateDialog(false)
        setOpenDeleteDialog(false)
        SelectGroup("")
        await GetGroupsAsync()
    }, [SelectGroup, setOpenCreateDialog, GetGroupsAsync, setOpenDeleteDialog])

    useEffect(() => {
        GetGroupsAsync();
        GetStylesAsync();
    }, [GetGroupsAsync, GetStylesAsync])

    

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button size="small" onClick={() => setOpenCreateDialog(true)}>Agregar Grupo</Button>
                </Grid>
                {groups.map(group => <Grid item xs={12} md={4} key={group.Id}>
                    <Card >
                        <CardContent onClick={() => RedirectToGroup(group.Id)} className={stylesCss.cardContent}>
                            <Typography variant="h4" className={stylesCss.title}>{group.Name}</Typography>

                            <Typography variant="body1">Nro Tarjetas: {group.NumberCards}</Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button size="small" onClick={() => {
                                SelectGroup(group.Id)
                                setOpenDeleteDialog(true)
                            }}>Borrar</Button>
                            <Button size="small" onClick={() => {
                                SelectGroup(group.Id)
                                setOpenCreateDialog(true)
                            }}>Cambiar Nombre</Button>
                        </CardActions>
                    </Card>
                </Grid>)}

            </Grid>

            <CreateUpdateGroupDialog Open={openCreateDialog}
                OnCloseCallback={OnCloseCallback}
                OnActionCallback={OnActionCallback}
                Group={selectedGroup}
                Styles={styles} />

            <DeleteGroupDialog Open={openDeleteDialog}
                OnCloseCallback={OnCloseCallback}
                OnActionCallback={OnActionCallback}
                Group={selectedGroup} />
        </>
    )
}
