import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'black',
      backgroundColor: 'transparent'
    },
  }),
);


interface LoadingProps {
    open: boolean
}

export default function Loading({ open }: LoadingProps) {
    const classes = useStyles();

    return (
        <>
            <Backdrop open={open} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
