import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useHttpRequest from 'Hooks/useHttpRequest'
import { AdminSuscription } from 'Models/Admin/Suscription';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react'

function AdminSuscriptionsContent() {

    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [suscriptions, setSuscriptions] = useState<AdminSuscription[]>([]);
    const [selectedSuscriptionId, setSelectedSuscriptionId] = useState<string>("");
    const [openDialog, setOpenDialog] = useState(false);

    const GetSuscriptions = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "admin/suscriptions",
            method: "GET",
            withToken: true,
            onSuccess: (suscriptions) => {
                setSuscriptions(suscriptions);
            }
        })
    }, [executeHttpRequest])

    const ApproveSuscription = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `admin/suscriptions/${selectedSuscriptionId}`,
            method: "PATCH",
            withToken: true,
            onSuccess: async () => {
                enqueueSnackbar("Suscripcion aprovada", { variant: "success" });
                setSelectedSuscriptionId("");
                setOpenDialog(false);
                await GetSuscriptions();
            }
        })
    }, [GetSuscriptions, enqueueSnackbar, executeHttpRequest, selectedSuscriptionId])

    useEffect(() => {
        GetSuscriptions();
    }, [GetSuscriptions])

    return (
        <div>
            {suscriptions.map(s => {
                return (
                    <Accordion key={s.Id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h4>{s.User.FirstName} {s.User.LastName} ({s.User.Email}) Tipo de cuenta: {s.User.AccountType} Telf:{s.User.PhoneNumber}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>
                                <h4>Número de Tarjetas Solicitas: {s.NumberCards}</h4>
                                <h4>Fecha Inicio Suscripción: {moment(s.InitialDate).format("DD/MM/YYYY")}</h4>
                                <h4>Fecha Finalizacion: {moment(s.EndingDate).format("DD/MM/YYYY")}</h4>
                            </pre>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button onClick={async () => { setSelectedSuscriptionId(s.Id); setOpenDialog(true) }}>Aprobar</Button>
                        </AccordionActions>
                    </Accordion>
                )
            })}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
                <DialogTitle>Aprobar Suscripción</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Aprobar esta Suscripción?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={async () => await ApproveSuscription()}>
                        Aprobar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AdminSuscriptionsContent
