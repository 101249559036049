import { Grid, Fab, Tooltip, IconButton } from '@material-ui/core'
import { Clear } from '@material-ui/icons';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import React, { useState } from 'react'


interface IProps {
    TextLabel: string;
    OnSelectedFileCallback: (file: File | null, fileUrl: string) => void;
    Id: string;
    ShowPreview: boolean;
}

function ImageSelector(props: IProps) {

    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>("");

    const OnSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList.length > 0) {
            let fileSelected = fileList.item(0);
            if (fileSelected) {
                await setFile(fileSelected);
                let reader = new FileReader();
                reader.readAsDataURL(fileSelected);
                reader.onloadend = async (e) => {
                    await setFileUrl(reader.result as string);
                    props.OnSelectedFileCallback(fileSelected, reader.result as string);

                }
            }
        }
    }

    const RemoveSelectedImage = () => {
        setFile(null);
        setFileUrl("");
        props.OnSelectedFileCallback(null, "");
    }

    return (
        <>
            <Grid item container xs={12} spacing={3}>
                <Grid item md={6} xs={12}>
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id={`contained-button-file-top-icon-${props.Id}`}
                        type="file"
                        onChange={OnSelectImage}
                    />
                    <label htmlFor={`contained-button-file-top-icon-${props.Id}`}>
                        <Fab component="span" color="primary" variant="extended" >
                            <AddPhotoAlternateIcon />
                            {props.TextLabel}
                        </Fab>
                    </label>
                    {file &&
                        <Tooltip title="Presione sobre la imagen para deselecionarla">
                            <IconButton color="primary" component="span" onClick={RemoveSelectedImage}>
                                <Clear />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
                {props.ShowPreview &&
                    <Grid item md={6} xs={12}>
                        <img src={fileUrl} alt="" style={{ height: "200px", maxWidth: "100%" }} />
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default ImageSelector
