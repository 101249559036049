import useHttpRequest from 'Hooks/useHttpRequest';
import { CreateEmptyUser, User } from 'Models/User/User';
import React, { useCallback, useEffect, useState } from 'react'

interface IAppContext {
    loading: boolean;
    setLoading: (value: boolean) => void;
    user: User;
    setUser: (user: User) => void;
}

const AppContext = React.createContext<IAppContext>({
    loading: false,
    setLoading: (value: boolean) => { },
    user: CreateEmptyUser(),
    setUser: (value: User | null) => { }
});



export default function AppContextProvider({ children }: any) {
    const { executeHttpRequest } = useHttpRequest();
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User>(CreateEmptyUser());


    const value22 = React.useMemo(() => {
        return {
            loading,
            setLoading,
            user,
            setUser
        }
    }, [loading, setLoading, user, setUser])

    const getUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setUser(data)
            }
        })
    }, [executeHttpRequest, setUser])

    useEffect(() => {
        if (localStorage.getItem("token"))
            getUserInfo()
    }, [getUserInfo])



    return (
        <AppContext.Provider value={value22}>{children}</AppContext.Provider>
    )
}

export const useAppState = () => {
    return React.useContext(AppContext);
}
