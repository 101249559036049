
import useHttpRequest from "Hooks/useHttpRequest";
import { Card, CreateEmptyCard } from "Models/Card/Card";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";


interface RouteParams {
    cardId: string
}

const useCardPublic = () => {

    const [card, setCard] = useState<Card>(CreateEmptyCard());

    const { cardId } = useParams<RouteParams>();
    const { executeHttpRequest } = useHttpRequest();
    // const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const getCardAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `public/card/${cardId}`,
            method: "GET",
            onSuccess: (data) => {
                setCard(data)
            },
            onError: (data) => {
                // enqueueSnackbar("Tarjeta no encontrada", { variant: "warning" })
                history.push("/");
            }
        })
    }, [cardId, executeHttpRequest, history])

    useEffect(() => {
        getCardAsync();
    }, [getCardAsync])

    return {
        card,
        cardId
    }
}

export default useCardPublic;