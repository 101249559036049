import React from 'react'

export default function DashBoardContent() {

    return (
        <div>
            <h1>DashBoard</h1>
        </div >
    )
}
