import MainLayout from 'Components/MainLayout'
import React from 'react'
import HelpContent from './HelpContent'

function HelpPage() {
    return (
        <div>
            <MainLayout mainContent={<HelpContent />} titleAppBar="Ayuda" />
        </div>
    )
}

export default HelpPage
