import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        minHeight: '60vh',
        color: 'black',
        padding: '1rem',
        background: '#E5E5E5'
    },
    title: {
        color: 'orange',
        marginBottom: '2rem',
        textAlign: 'center',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'center',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
        display: "block",
        margin: 'auto'
    },
    leftContent: {
        padding: '3.5rem'

    },
    rightContent: {
        padding: '3.5rem',
    }
}));

export default function Section5() {
    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" className={classes.section1}>


            <Grid item container direction="column" xs={12} md={6} alignItems="flex-start" justify="center" className={classes.leftContent}>

                <Typography variant="h3" className={classes.title}>
                    No necesitas descargar una App
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    No ocupa espacio en tu teléfono celular
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Todos podrán llevar la tarjeta en la pantalla de inicio de Teléfono Móvil
                </Typography>



            </Grid>
            <Grid item xs={12} md={6}  container justify="center">
                <img src="/section5.png" alt="" className={classes.image}  />
            </Grid>

        </Grid>


    );
}

