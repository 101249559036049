import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { AccountCircle, ArtTrack, AttachMoney, ExitToApp, Help, RecentActors } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'

export default function SideBarOptions() {

    const history = useHistory();

    const redirectTo = (url: string) => {
        history.push(url);
    }

    const logOut = () => {
        localStorage.clear();
        redirectTo("/");
    }

    return (
        <div>
            <Divider />
            <List>

                <ListItem button onClick={(e) => redirectTo("/tarjetas")}>
                    <ListItemIcon> <RecentActors /> </ListItemIcon>
                    <ListItemText primary="Tarjetas" />
                </ListItem>

                <ListItem button onClick={(e) => redirectTo("/estilos")}>
                    <ListItemIcon> <ArtTrack /> </ListItemIcon>
                    <ListItemText primary="Estilos" />
                </ListItem>

            </List>
            <Divider />
            <List>
                <ListItem button onClick={(e) => redirectTo("/suscripciones")}>
                    <ListItemIcon> <AttachMoney /> </ListItemIcon>
                    <ListItemText primary="Suscripciones" />
                </ListItem>
                <ListItem button onClick={(e) => redirectTo("/cuenta")}>
                    <ListItemIcon> <AccountCircle /> </ListItemIcon>
                    <ListItemText primary="Cuenta" />
                </ListItem>

                <ListItem button onClick={(e) => redirectTo("/ayuda")}>
                    <ListItemIcon> <Help /> </ListItemIcon>
                    <ListItemText primary="Ayuda" />
                </ListItem>

                <ListItem button onClick={(e) => logOut()}>
                    <ListItemIcon> < ExitToApp /> </ListItemIcon>
                    <ListItemText primary="Cerrar Sesión" />
                </ListItem>

            </List>
        </div>
    )
}
