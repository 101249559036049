export interface Style {
    Id: string;
    Name: string;
    ColorTheme: string;
    IconColor: string;
    UserId: string;
    // Common Fields with card
    IconUrl: string;
    BannerUrl: string;
    BusinessPage: string;
    Business: string;
    Twitter: string;
    Facebook: string;
    Linkedin: string;
    Address: string;
    Video: string;
}

export const CreateEmtpyStyle = () => ({
    Id: "",
    Name: "",
    ColorTheme: "",
    IconColor: "",
    UserId: "",
    IconUrl: "",
    BannerUrl: "",
    BusinessPage: "",
    Business: "",
    Twitter: "",
    Facebook: "",
    Linkedin: "",
    Address: "",
    Video: ""
} as Style)