import { Button, Fab, Grid, Hidden, IconButton, makeStyles } from '@material-ui/core';
import { Call, CropFree, Facebook, FileCopy, GetApp, LinkedIn, LocationOn, MailOutline, Twitter, Videocam, WhatsApp } from '@material-ui/icons';
import { STORAGE_URL, UI_URL, API_URL } from 'Api/ApiConfig';
import { useSnackbar } from 'notistack';
import React from 'react'
import useCardPublic from './useCardPublic';

const useStyles = makeStyles((theme) => ({
    gridTopIconContainer: {
        height: "10%"
    },
    gridImagesContainer: {
        height: "30%",
        background: "#E5E5E5"
    },
    gridInformationContainer: {
        // height: "60%",
        paddingTop: "115px",
        background: "#E5E5E5"
    },
    topIcon: {
        height: "100%",
        display: "block",
        margin: "auto"
    },
    bannerImage: {
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "row-reverse"
    },
    avatarImage: {
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        display: "block",
        margin: "auto",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        top: "-95px"
    },
    footerContainer: {
        background: "gray",
        display: "flex",
        justifyContent: "space-around",
    },
    footerActionItem: {
        color: "white",
        fontSize: "35px"
    },
    personalInfo: {
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "10px"
    },
    actionButtonContainer: {
        display: "flex",
        cursor: "pointer",
        marginTop: "10px",
        justifyContent: "center"
    },
    actionButton: {
        display: "block",
        margin: "auto",
        marginRight: "10px",
        marginLeft: "5px"
    },
    inlineActionButtonsContainer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        "&>*": {
            marginRight: "20px"
        }
    },
    visitPageContainer: {
        width: "100%",
        marginTop: "20px"
    },
    trovamiboLink: {
        textAlign: "center",
        marginTop: "20px",
        cursor: "pointer"
    },
    actionBar: {
        background: "rgba(255,255,255,0.2)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    }
}));

export default function CardPublic() {
    const { enqueueSnackbar } = useSnackbar();
    const { card, cardId } = useCardPublic();
    const styles = useStyles();

    // const StorageUrl = "https://storage.trovamibo.com";
    const StorageUrl = STORAGE_URL;

    const url = `${UI_URL}/tarjeta/${cardId}`

    const BuilderUrl = (directory: string, url: string) => {
        return `${StorageUrl}/${directory}/${url}?${performance.now()}`;
    }

    const redirectTo = (target: string, value: string) => {
        switch (target) {
            case "CALL":
                (window.location as any) = `tel:${value}`
                break;
            case "MAIL":
                (window.location as any) = `mailto:${value}`
                break;
            case "WHATSAPP":
                (window.location as any) = `https://wa.me/+591${value}`
                break;
            case "LINK":
                (window.location as any) = value;
                break;
            case "MAP":
                (window.location as any) = `https://maps.google.com/?q=${value}`
                break;
            default:
                break;
        }
    }

    const ApplyThemeToCall = () => {
        if (card.Phone)
            return { background: card.ColorTheme, color: card.IconColor }
    }

    const ApplyThemeToWhatsApp = () => {
        if (card.WhatsApp)
            return { background: card.ColorTheme, color: card.IconColor }
    }

    const ApplyThemeToEmail = () => {
        if (card.Email)
            return { background: card.ColorTheme, color: card.IconColor }
    }

    const ApplyThemeToAddress = () => {
        if (card.Address)
            return { color: card.IconColor }
        else
            return { color: "gray" }
    }
    const ApplyThemeToVideo = () => {
        if (card.Video)
            return { color: card.IconColor }
        else
            return { color: "gray" }
    }

    const CopyToClipboard = async () => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(url)
            enqueueSnackbar("Url copiada al portapapeles", { variant: "success" })
        } else {
            const textField = document.createElement('textarea');
            textField.innerText = url;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            enqueueSnackbar("Url copiada al portapapeles", { variant: "success" })
        }
    }

    const DownloadVCard = () => {
        let downloadLink = document.createElement("a");
        downloadLink.href = `${API_URL}/public/card/${card.Id}/vcard`;
        downloadLink.download = `${card.Name} ${card.LastName}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
    }

    const DownloadQR = async () => {
        // const canvas = document.getElementById("qr");
        // const pngUrl = (canvas! as any).toDataURL("image/png")
        let downloadLink = document.createElement("a");
        downloadLink.href = `${API_URL}/public/card/${card.Id}/qr`;
        downloadLink.download = `${card.Name} ${card.LastName}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
    }

    return (
        <div>
            <Grid container style={{ height: '100vh', width: "100wh" }}>
                <Hidden xsDown >
                    <Grid item lg={4} style={{ height: "100%" }}>


                    </Grid>
                </Hidden>
                <Grid item container lg={4} xs={12} style={{ maxHeight: "100%" }} direction="row" justify="center">
                    <Grid item xs={12} className={styles.gridTopIconContainer} >
                        {card.IconUrl !== "" && <img className={styles.topIcon} src={BuilderUrl(card.UserId, card.IconUrl)} alt="test"></img>}
                    </Grid>
                    <Grid item xs={12} className={styles.gridImagesContainer}>
                        {card.BannerUrl !== "" && <div className={styles.bannerImage} style={{ backgroundImage: `url(${BuilderUrl(card.UserId, card.BannerUrl)})` }}>
                            <div className={styles.actionBar}>
                                <IconButton component="span" style={{ color: "white" }} onClick={DownloadQR}>
                                    <CropFree />
                                </IconButton>
                                <IconButton component="span" style={{ color: "white" }} onClick={DownloadVCard} >
                                    <GetApp />
                                </IconButton>
                                <IconButton component="span" style={{ color: "white" }} onClick={CopyToClipboard}>
                                    <FileCopy />
                                </IconButton>
                            </div>
                        </div>}
                        {card.AvatarUrl !== "" && <div className={styles.avatarImage} style={{ backgroundImage: `url(${BuilderUrl(card.UserId, card.AvatarUrl)})` }} ></div>}




                    </Grid>
                    <Grid item xs={12} className={styles.gridInformationContainer}>
                        <h2 className={styles.personalInfo}>{card.Name} <span style={{ color: card.ColorTheme }}>{card.LastName}</span> </h2>
                        <h2 className={styles.personalInfo}>{card.Position}</h2>
                        <h2 className={styles.personalInfo} style={{ color: card.ColorTheme }}>{card.Business}</h2>

                        <div className={styles.actionButtonContainer} >


                            <Fab className={styles.actionButton} onClick={() => redirectTo("CALL", card.Phone)} size="medium" style={ApplyThemeToCall()} disabled={card.Phone === ""}>
                                <Call />
                            </Fab>
                            <h4>LLAMADA&nbsp;&nbsp;</h4>

                        </div>
                        <div className={styles.actionButtonContainer} >
                            <Fab className={styles.actionButton} onClick={() => redirectTo("WHATSAPP", card.Phone)} size="medium" style={ApplyThemeToWhatsApp()} disabled={!card.WhatsApp}>
                                <WhatsApp />
                            </Fab>
                            <h4>WHATSAPP</h4>
                        </div>
                        <div className={styles.actionButtonContainer} >
                            <Fab className={styles.actionButton} onClick={() => redirectTo("MAIL", card.Email)} size="medium" style={ApplyThemeToEmail()} disabled={card.Email === ""}>
                                <MailOutline />
                            </Fab>
                            <h4>E-MAIL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                        </div>
                        <div className={styles.visitPageContainer}>
                            <Button color="primary"
                                variant="outlined"
                                disabled={card.BussinessPage === ""}
                                onClick={() => redirectTo("LINK", card.BussinessPage)}
                                style={{ margin: "auto", display: "block", width: "90%", height: "70px", borderColor: card.ColorTheme, color: card.ColorTheme }}>
                                Visita mi sitio web</Button>
                        </div>
                        <div className={styles.inlineActionButtonsContainer} >
                            <Fab onClick={() => redirectTo("LINK", card.Twitter)} size="medium" disabled={card.Twitter === ""}>
                                <Twitter />
                            </Fab>
                            <Fab onClick={() => redirectTo("LINK", card.Facebook)} size="medium" disabled={card.Facebook === ""}>
                                <Facebook />
                            </Fab>
                            <Fab onClick={() => redirectTo("LINK", card.Linkedin)} size="medium" disabled={card.Linkedin === ""}>
                                <LinkedIn />
                            </Fab>
                        </div>

                        <h4 className={styles.trovamiboLink} onClick={() => redirectTo("LINK", "https://trovamibo.com")}>Trovamibo.com</h4>
                    </Grid>
                    <Grid item xs={12} >
                        <div className={styles.footerContainer} style={{ background: card.ColorTheme }}>
                            <IconButton disabled={card.Address === ""} onClick={() => redirectTo("MAP", card.Address)}>
                                <LocationOn className={styles.footerActionItem} style={ApplyThemeToAddress()} />

                            </IconButton>
                            <IconButton onClick={() => redirectTo("LINK", card.Video)} disabled={card.Video === ""}>
                                <Videocam className={styles.footerActionItem} style={ApplyThemeToVideo()} />

                            </IconButton>
                        </div>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item lg={4} xs={1} >

                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    )
}

