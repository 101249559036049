import { Button, Grid, TextField } from '@material-ui/core'
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';
import ResetPasswordDialog from 'Pages/Login/ResetPasswordDialog';
import React from 'react'
import useAccountContent from './useAccountContent'

function AccountContent() {
    const { user, UpdateUserInfo, setUserValues, setShowResetPasswordDialog, showResetPasswordDialog, userValidation } = useAccountContent();
    return (
        <div>

            <Grid container spacing={5}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Correo Electrónico"
                        variant="outlined"
                        disabled
                        name="Email"
                        value={user.Email}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        name="FirstName"
                        onChange={setUserValues}
                        error={inputHasError(userValidation.FirstName)}
                        helperText={createInputErrorMessage(userValidation.FirstName, "")}
                        value={user.FirstName}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Apellido"
                        variant="outlined"
                        onChange={setUserValues}
                        error={inputHasError(userValidation.LastName)}
                        helperText={createInputErrorMessage(userValidation.LastName, "")}
                        name="LastName"
                        value={user.LastName}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="CI/NIT"
                        variant="outlined"
                        name="CI"
                        onChange={setUserValues}
                        error={inputHasError(userValidation.CI)}
                        helperText={createInputErrorMessage(userValidation.CI, "")}
                        value={user.CI}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Teléfono"
                        variant="outlined"
                        name="PhoneNumber"
                        onChange={setUserValues}
                        error={inputHasError(userValidation.PhoneNumber)}
                        helperText={createInputErrorMessage(userValidation.PhoneNumber, "")}
                        value={user.PhoneNumber}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Tipo de Cuenta"
                        variant="outlined"
                        disabled
                        value={user.AccountType}
                        fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        onClick={UpdateUserInfo}
                        variant="contained"
                        color="secondary"
                        fullWidth >
                        Actualizar Datos
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        onClick={() => setShowResetPasswordDialog(true)}
                        variant="contained"
                        color="primary"
                        fullWidth >
                        Cambiar Contraseña
                    </Button>
                </Grid>
            </Grid>
            {showResetPasswordDialog && <ResetPasswordDialog Open={showResetPasswordDialog} OnCloseCallback={() => setShowResetPasswordDialog(false)} email={user.Email} />}
        </div>
    )
}

export default AccountContent
