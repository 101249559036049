import { Button, Fab, Grid, Hidden, IconButton, makeStyles } from '@material-ui/core'
import { Call, Facebook, LinkedIn, LocationOn, MailOutline, Twitter, Videocam, WhatsApp } from '@material-ui/icons';
import { STORAGE_URL } from 'Api/ApiConfig';
import { Style } from 'Models/Styles/Style'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    gridTopIconContainer: {
        height: "10%"
    },
    gridImagesContainer: {
        height: "30%",
        background: "#E5E5E5"
    },
    gridInformationContainer: {
        // height: "60%",
        paddingTop: "115px",
        background: "#E5E5E5"
    },
    topIcon: {
        height: "100%",
        display: "block",
        margin: "auto"
    },
    bannerImage: {
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    avatarImage: {
        height: "200px",
        width: "200px",
        borderRadius: "50%",
        display: "block",
        margin: "auto",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        top: "-95px",
    },
    footerContainer: {
        background: "gray",
        display: "flex",
        justifyContent: "space-around",
    },
    footerActionItem: {
        color: "white",
        fontSize: "35px"
    },
    personalInfo: {
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "10px"
    },
    actionButtonContainer: {
        display: "flex",
        cursor: "pointer",
        marginTop: "10px",
        justifyContent: "center"
    },
    actionButton: {
        display: "block",
        margin: "auto",
        marginRight: "10px",
        marginLeft: "5px"
    },
    inlineActionButtonsContainer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        "&>*": {
            marginRight: "20px"
        }
    },
    visitPageContainer: {
        width: "100%",
        marginTop: "20px"
    },
    trovamiboLink: {
        textAlign: "center",
        marginTop: "20px",
        cursor: "pointer"
    }
}));

interface IProps {
    style: Style
}

export default function StylePreview(props: IProps) {
    const styles = useStyles();

    const BuilderUrl = (url: string) => {
        if (url.startsWith("data:")) {
            return url;
        } else {
            const StorageUrl = STORAGE_URL;

            return `${StorageUrl}/${props.style.UserId}/${url}?${performance.now()}`
        }
    }

    return (
        <div style={{ height: "100vh", width: "100%" }}>

            <Grid container style={{ height: "100%", width: "100%" }} >
                <Hidden xsDown>
                    <Grid item lg={2} style={{ height: "100%" }}>

                    </Grid>
                </Hidden>
                <Grid item container lg={8} xs={12} style={{ maxHeight: "100%" }} direction="row" justify="center">
                    <Grid item xs={12} className={styles.gridTopIconContainer} >
                        {<img className={styles.topIcon} src={BuilderUrl(props.style.IconUrl)} alt="test"></img>}
                    </Grid>
                    <Grid item xs={12} className={styles.gridImagesContainer}>
                        {<div className={styles.bannerImage} style={{ backgroundImage: `url(${BuilderUrl(props.style.BannerUrl)})` }}></div>}
                        {<div className={styles.avatarImage} style={{ backgroundImage: `url(/user.png)` }} ></div>}



                    </Grid>
                    <Grid item xs={12} className={styles.gridInformationContainer}>
                        <h2 className={styles.personalInfo}>{`Nombres`} <span style={{ color: props.style.ColorTheme }}>{'Apellidos'}</span> </h2>
                        <h2 className={styles.personalInfo}>{`Trovamibo Test`}</h2>
                        <h2 className={styles.personalInfo} style={{ color: props.style.ColorTheme }}>{props.style.Business}</h2>

                        <div className={styles.actionButtonContainer} >


                            <Fab className={styles.actionButton} size="medium" style={{ background: props.style.ColorTheme, color: props.style.IconColor }}>
                                <Call />
                            </Fab>
                            <h4>LLAMADA&nbsp;&nbsp;</h4>

                        </div>
                        <div className={styles.actionButtonContainer} >
                            <Fab className={styles.actionButton} size="medium" style={{ background: props.style.ColorTheme, color: props.style.IconColor }}>
                                <WhatsApp />
                            </Fab>
                            <h4>WHATSAPP</h4>
                        </div>
                        <div className={styles.actionButtonContainer} >
                            <Fab className={styles.actionButton} size="medium" style={{ background: props.style.ColorTheme, color: props.style.IconColor }}>
                                <MailOutline />
                            </Fab>
                            <h4>E-MAIL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                        </div>
                        <div className={styles.visitPageContainer}>

                            <Button color="primary"
                                disabled={props.style.BusinessPage.trimStart() === ""}
                                variant="outlined"
                                style={{ margin: "auto", display: "block", width: "90%", height: "70px", borderColor: props.style.ColorTheme, color: props.style.ColorTheme }}>
                                Visita mi sitio web</Button>
                        </div>
                        <div className={styles.inlineActionButtonsContainer} >
                            <Fab size="medium" disabled={props.style.Twitter === ""}>
                                <Twitter />
                            </Fab>
                            <Fab size="medium" disabled={props.style.Facebook === ""}>
                                <Facebook />
                            </Fab>
                            <Fab size="medium" disabled={props.style.Linkedin === ""}>
                                <LinkedIn />
                            </Fab>
                        </div>

                        <h4 className={styles.trovamiboLink} >Trovamibo.com</h4>
                    </Grid>
                    <Grid item xs={12} >
                        <div className={styles.footerContainer} style={{ background: props.style.ColorTheme }}>
                            <IconButton disabled={props.style.Address === ""} >
                                <LocationOn className={styles.footerActionItem} style={{ color: props.style.IconColor }} />

                            </IconButton>
                            <IconButton disabled={props.style.Video === ""}>
                                <Videocam className={styles.footerActionItem} style={{ color: props.style.IconColor }} />

                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <Hidden xsDown>
                    <Grid item lg={2} style={{ height: "100%" }}>

                    </Grid>
                </Hidden>

            </Grid>

        </div >
    )
}