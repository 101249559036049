import { AccountType } from "./AccountType";

export interface User {
    Email: string,
    Password: string,
    FirstName: string,
    LastName: string,
    CI: string,
    PhoneNumber: string,
    AccountType: AccountType
}

export const CreateEmptyUser = () => ({
    Email: "",
    FirstName: "",
    LastName: "",
    CI: "",
    PhoneNumber: "",
    AccountType: AccountType.PERSONAL,
    Password: ""
} as User)