import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useHttpRequest from 'Hooks/useHttpRequest';
import { Contact } from 'Models/Contact/Contact'
import React, { useCallback, useEffect, useState } from 'react'
import moment from "moment";
import { useSnackbar } from 'notistack';

function AdminMessagesContent() {
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
    const [openDialog, setOpenDialog] = useState(false);

    const GetMessages = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "admin/messages",
            method: "GET",
            withToken: true,
            onSuccess: (messages) => {
                setContacts(messages)
            }
        })
    }, [executeHttpRequest])

    const DeleteMessage = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `admin/messages/${selectedContact?.Id}`,
            method: "DELETE",
            withToken: true,
            onSuccess: async () => {
                enqueueSnackbar("Mensaje borrado correctamente", { variant: "success" })
                setSelectedContact(null);
                setOpenDialog(false);
                await GetMessages();
            }
        })
    }, [enqueueSnackbar, executeHttpRequest, selectedContact?.Id, GetMessages])

    const SelectContact = (contactId: string) => {
        const contact = contacts.find(c => c.Id === contactId);
        if (contact) {
            setSelectedContact(contact);
        } else {
            setSelectedContact(null);
        }
    }

    useEffect(() => {
        GetMessages();
    }, [GetMessages])


    return (
        <div>
            {contacts.map(c => {
                return (
                    <Accordion key={c.Id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h4>{c.Email} ({moment(c.DateSubmited).format("DD/MM/YYYY HH:mm:ss")})</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>
                                {c.Message}
                            </pre>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button onClick={async () => { SelectContact(c.Id); setOpenDialog(true) }}>Eliminar</Button>
                        </AccordionActions>
                    </Accordion>
                )
            })

            }
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
                <DialogTitle>Borrar Mensaje</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta seguro que desea eliminar este mensaje?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancelar
                     </Button>
                    <Button color="primary" onClick={async () => await DeleteMessage()}>
                        Eliminar Mensaje
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AdminMessagesContent
