import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import useHttpRequest from 'Hooks/useHttpRequest';
import { Group } from 'Models/Groups/Group'
import React, { useCallback } from 'react'


interface IProps {
    OnCloseCallback: () => void,
    OnActionCallback: () => void,
    Group: Group,
    Open: boolean
}

function DeleteGroupDialog(props: IProps) {
    // Hooks
    const { executeHttpRequest } = useHttpRequest();
    // Api Methods
    const DeleteGroupAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `group/${props.Group.Id}`,
            method: "DELETE",
            withToken: true,
            onSuccess: (deletedGroup) => {
                props.OnActionCallback();
            }
        })

    }, [executeHttpRequest, props])

    return (
        <div>
            <Dialog open={props.Open} onClose={props.OnCloseCallback} fullWidth>
                <DialogTitle>Borrar Grupo {props.Group.Name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta seguro que desea eliminar este grupo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.OnCloseCallback} color="primary">
                        Cancelar
                     </Button>
                    <Button color="primary" onClick={DeleteGroupAsync}>
                        Eliminar Grupo
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteGroupDialog
