import MainLayout from 'Components/MainLayout'
import GroupsContent from 'Pages/Group/GroupsContent'
import React from 'react'

export default function GroupsPage() {

    return (
        <div>
            <MainLayout mainContent={<GroupsContent />} titleAppBar="Grupos de Tarjetas" />
        </div>
    )
}
