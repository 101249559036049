import { Grid } from '@material-ui/core'
import ContactForm from 'Components/ContactForm/ContactForm'
import useHttpRequest from 'Hooks/useHttpRequest';
import { User, CreateEmptyUser } from 'Models/User/User';
import React, { useCallback, useEffect, useState } from 'react'

function HelpContent() {
    const [user, setUserInfo] = useState<User>(CreateEmptyUser());
    const { executeHttpRequest } = useHttpRequest();

    const GetUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "GET",
            withToken: true,
            onSuccess: (user) => {
                setUserInfo(user);
            }
        })
    }, [executeHttpRequest])

    useEffect(() => {
        GetUserInfo()
    }, [GetUserInfo])

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <ContactForm email={user.Email} />

                </Grid>
            </Grid>
        </div>
    )
}

export default HelpContent
