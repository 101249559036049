import useHttpRequest from "Hooks/useHttpRequest";
import { AccountType } from "Models/User/AccountType";
import { User } from "Models/User/User";
import { UserValidation } from "Models/User/UserValidation";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";


const useSignUp = () => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { executeHttpRequest } = useHttpRequest();
    const [user, setUserState] = useState<User>({
        Email: "",
        Password: "",
        FirstName: "",
        LastName: "",
        CI: "",
        PhoneNumber: "",
        AccountType: AccountType.PERSONAL
    })

    const [userValidation, setUserValidation] = useState<UserValidation>({
        Email: [],
        FirstName: [],
        LastName: [],
        Password: [],
        PhoneNumber: [],
        CI: [],
        AccountType: []
    })

    const [repeatPassword, setRepeatPassword] = useState<string>("");

    const signIn = async () => {
        if (user.Password === repeatPassword) {

            await executeHttpRequest({
                endpoint: "user",
                method: "POST",
                body: user,
                onValidationError: (data) => {
                    setUserValidation(data)
                },
                onSuccess: (data) => {
                    enqueueSnackbar("Usuario registrado Correctamente", { variant: "success" })
                    setUserValidation({
                        Email: [],
                        FirstName: [],
                        LastName: [],
                        Password: [],
                        PhoneNumber: [],
                        CI: [],
                        AccountType: []
                    })
                    enqueueSnackbar(`Un código de confirmación fue enviado a ${data.Email}, Use este código cuando inicie sesión por primera vez`, { variant: "warning", autoHideDuration: 7000 });
                    history.push("/login")
                }
            });
        } else {
            enqueueSnackbar("Las contraseñas no coinciden", { variant: "warning" })
        }
    }


    const setUserValues = (e: any) => {
        const { name, value } = e.target;

        setUserState(preState => ({
            ...preState,
            [name]: value
        }))
    }



    return {
        user,
        signIn,
        setUserValues,
        userValidation,
        repeatPassword,
        setRepeatPassword
    }

}

export default useSignUp;