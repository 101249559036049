import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { } from 'react'

interface IProps {
    OnCloseCallback: () => void,
    Open: boolean
}

function MapHelpDialog(props: IProps) {

    return (
        <div>
            <Dialog open={props.Open} onClose={props.OnCloseCallback} fullWidth>
                <DialogTitle>Ayuda Dirección</DialogTitle>
                <DialogContent>
                    <ul>
                        <li>Ve a <a href="https://maps.google.com/" rel="noreferrer" target="_blank">Google Maps</a></li>
                        <li>Desde un Ordenador/PC: Dale click derecho sobre la dirección de tu negocio y seleciona la primera opcion
                            <img src="/mapHelp.png" alt="" width="100%" />
                        </li>
                        <li>Desde un Dispositivo Móvil: Presiona sobre la dirección de tu negocio y copia el texto de la parte superior
                            <img src="/mapHelpMobile.jpg" alt="" width="100%" />
                        </li>
                        <li>Finalmente pega el texto copiado sobre el campo de <strong>Dirección</strong></li>
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.OnCloseCallback} color="primary">
                        Cerrar
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MapHelpDialog

