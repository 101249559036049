
export interface CardValidation {
    Name: string[],
    LastName: string[],
    Position: string[],
    Email: string[],
    Phone: string[]
}


export const CreateEmptyCardValidation = () => ({
    Name: [],
    LastName: [],
    Position: [],
    Email: [],
    Phone: []
} as CardValidation)