import MainLayout from 'Components/MainLayout'
import React from 'react'
import SuscriptionsContent from './SuscriptionsContent'

function SuscriptionsPage() {
    return (
        <div>
            <MainLayout mainContent={<SuscriptionsContent />} titleAppBar="Suscripciones" />
        </div>
    )
}

export default SuscriptionsPage
