import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        minHeight: '60vh',
        color: 'black'
    },
    title: {
        color: 'orange',
        marginBottom: '2rem',
        textAlign: 'start',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'start',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
        display: "block",
        margin: 'auto'
    },
    leftContent: {
        padding: '3.5rem',
        paddingBottom:"0"

    },
    rightContent: {

    }
}));

export default function Section6() {
    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" className={classes.section1}>


            <Grid item container direction="column" xs={12} md={6} alignItems="flex-start" justify="center" className={classes.leftContent}>

                <Typography variant="h3" className={classes.title}>
                    Múltiples usos
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Podrás compartir tu información pública y privada en diferentes situaciones, presentes o remotas
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Mejora tu red de trabajo de manera sencilla e innovadora
                </Typography>

            </Grid>
            <Grid item xs={12} md={6} className={classes.rightContent}>
                <img src="/section2.png" alt="" className={classes.image} width="100%" />
            </Grid>


        </Grid>


    );
}

