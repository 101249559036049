import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import useHttpRequest from 'Hooks/useHttpRequest'
import { inputHasError, createInputErrorMessage } from 'lib/ValidatorHelper'
import { Group } from 'Models/Groups/Group'
import { GroupValidation } from 'Models/Groups/GroupValidation'
import { Style } from 'Models/Styles/Style'
import React, { useCallback, useEffect, useState } from 'react'

interface IProps {
    Group: Group | null,
    Open: boolean,
    OnCloseCallback: () => void,
    OnActionCallback: () => void,
    Styles: Style[]
}

function CreateUpdateGroupDialog(props: IProps) {
    // Hooks
    const { executeHttpRequest } = useHttpRequest();
    // States
    const [Group, setGroup] = useState<Group>({
        Id: "",
        Name: "",
        NumberCards: 0,
        StyleId: "",
        UserId: ""
    })
    const [groupValidation, setGroupValidation] = useState<GroupValidation>({
        Name: [],
        StyleId: []
    });
    const [styles, setStyles] = useState<Style[]>([]);

    // Api Methods
    const UpdateGroupAsync = useCallback(async () => {
        await executeHttpRequest({
            endpoint: `group/${Group?.Id}`,
            method: "PUT",
            withToken: true,
            body: Group,
            onSuccess: async (data) => {
                props.OnActionCallback();
                setGroupValidation({
                    Name: [],
                    StyleId: []
                })
            },
            onValidationError: (data) => {
                setGroupValidation(data)
            }
        })

    }, [executeHttpRequest, Group, props])

    const CreateGroupAsync = useCallback(async () => {

        await executeHttpRequest({
            endpoint: "group",
            method: "POST",
            withToken: true,
            body: Group,
            onSuccess: (createdGroup) => {
                props.OnActionCallback();
                setGroupValidation({
                    Name: [],
                    StyleId: []
                })
            },
            onValidationError: (data) => {
                setGroupValidation(data);

            }
        })

    }, [executeHttpRequest, Group, props])

    // Helper Methods
    const SetGroupSelectedValues = (e: any) => {
        const { name, value } = e.target;

        setGroup(preState => ({
            ...preState,
            [name]: value
        }))
    }

    const PerformAction = useCallback(async () => {
        if (Group.Id === "") {
            await CreateGroupAsync();
        } else {
            await UpdateGroupAsync();
        }
    }, [Group.Id, CreateGroupAsync, UpdateGroupAsync])

    const CloseAction = useCallback(() => {
        setGroupValidation({
            Name: [],
            StyleId: []
        })
        props.OnCloseCallback()
    }, [setGroupValidation, props])

    useEffect(() => {
        if (props.Group) {
            setGroup(props.Group)
        } else {
            setGroup({
                Id: "",
                Name: "",
                NumberCards: 0,
                StyleId: "",
                UserId: ""
            })
        }
        setStyles(props.Styles)
    }, [props.Group, props.Styles])

    return (
        <div>
            <Dialog open={props.Open} onClose={CloseAction} fullWidth>
                <form autoComplete="on" noValidate onSubmit={(e) => { e.preventDefault(); PerformAction(); }}>
                    <DialogTitle>{Group.Id === "" ? "Crear Grupo" : "Actualizar Grupo"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            value={Group?.Name}
                            onChange={(e) => SetGroupSelectedValues(e)}
                            autoFocus
                            margin="dense"
                            name="Name"
                            label="Nombre del Grupo"
                            fullWidth
                            error={inputHasError(groupValidation.Name)}
                            helperText={createInputErrorMessage(groupValidation.Name)}
                        />
                        <FormControl variant="filled" style={{ width: "100%", marginTop: "1rem" }} error={inputHasError(groupValidation.StyleId)}>
                            <InputLabel id="styleSelector">Estilo</InputLabel>
                            <Select labelId="styleSelector"
                                value={Group?.StyleId}
                                name="StyleId"
                                fullWidth
                                onChange={(e) => SetGroupSelectedValues(e)}>
                                {styles.map(s =>
                                    <MenuItem value={s.Id} key={s.Id}>{s.Name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{createInputErrorMessage(groupValidation.StyleId)}</FormHelperText>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={CloseAction} color="primary">
                            Cancelar
                     </Button>
                        <Button color="primary" type="submit">
                            {Group.Id === "" ? "Crear" : "Actualizar"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default CreateUpdateGroupDialog
