import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    section1: {
        maxWidth: "1000vw",
        color: 'white',
        padding: '3rem'
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '2rem'
    },
    paragraph: {
        marginBottom: '2rem'
    },
    button: {
        marginRight: '1rem'
    },
    mainColor: {
        color: theme.palette.secondary.main
    }
}));

export default function Section1() {
    const classes = useStyles();
    const history = useHistory();

    return (

        <Grid container className={classes.section1} style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#223242" }}>

            <Grid item container direction="column" alignContent="center" xs={12} md={7}>

                <Typography variant="h3" className={classes.title}>
                    Tarjetas Digitales
                </Typography>
                <Typography variant="h4" className={classes.paragraph}>
                    {`¡Porque la primera impresión`} <span className={classes.mainColor}>cuenta</span>!
                </Typography>
                <Typography variant="h4">
                    Descubre la version digital de las Tarjetas
                </Typography>
                <Typography variant="h4">
                    tradicionales y comparte tu información de
                </Typography>
                <Typography variant="h4" className={classes.paragraph}>
                    manera eco amigable
                </Typography>
                <Grid item>
                    <Button color="secondary" className={classes.button} variant="contained" onClick={() => history.push("signin")}>Creala ahora</Button>
                </Grid>

            </Grid>
            <Grid item xs={12} md={5} container justify="center">
                <img src="/section5.png" alt="" height="100%" />
            </Grid>
        </Grid >


    );
}

