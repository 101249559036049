import MainLayoutAdmin from 'Components/MainLayoutAdmin'
import React from 'react'
import AdminSuscriptionsContent from './AdminSuscriptionsContent'

function AdminSuscriptionPage() {
    return (
        <div>
            <MainLayoutAdmin mainContent={<AdminSuscriptionsContent />} titleAppBar="Suscripciones pendientes" />
        </div>
    )
}

export default AdminSuscriptionPage
