export interface ResetPassword {
    Password: string,
    UserEmail: string,
    VerificationCode: string
}

export const CreateEmtpyResetPassword = () => ({
    Password: "",
    UserEmail: "",
    VerificationCode: ""
} as ResetPassword)