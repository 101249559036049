import MainLayout from 'Components/MainLayout'
import React from 'react'
import CardsContent from '../CardContent/CardsContent'
import useCardPage from './useCardPage'

export default function CardsPage() {
    const { group } = useCardPage();

    return (
        <div>
            <MainLayout mainContent={<CardsContent />} titleAppBar={group?.Name ? `Tarjetas de ${group.Name}` : ""} />
        </div>
    )
}
