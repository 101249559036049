import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        background: '#E5E5E5',
        
        color: 'black',
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '2rem',
        textAlign: 'center',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'center',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
        display: "block",
        margin: 'auto'
    },
    leftContent: {
        padding: '3.5rem',
        paddingBottom: "0"
    },
    rightContent: {

    }
}));

export default function Section3() {
    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" className={classes.section1}>


            <Grid item container direction="column" xs={12} md={6} alignItems="flex-start" justify="center" className={classes.leftContent}>

                <Typography variant="h3" className={classes.title}>
                    Gestión de Redes Sociales
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Integra tus redes sociales y tus webs, y facilita su acceso automatizado y directo
                </Typography>

            </Grid>
            <Grid item xs={12} md={6} className={classes.rightContent}>
                <img src="/section3.png" alt="" className={classes.image} width="100%" />
            </Grid>

        </Grid>


    );
}

