import { useAppState } from "Context/AppContext";
import useHttpRequest from "Hooks/useHttpRequest";
import { LoginModel } from "Models/Login/Login";
import { LoginValidation } from "Models/Login/UserValidation";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";


const useLogin = () => {
    const { setUser } = useAppState();
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [login, setLogin] = useState<LoginModel>({
        username: "",
        password: ""
    });

    const [loginValidation, setLoginValidation] = useState<LoginValidation>({
        Email: [],
        Password: []
    })

    const [showConfirmationDialog, setShowConfirmationdialog] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    const getUserInfo = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "user",
            method: "GET",
            withToken: true,
            onSuccess: (data) => {
                setUser(data)
            }
        })
    }, [executeHttpRequest, setUser])

    const signIn = async () => {
        if (!(login.username && login.password)) {
            enqueueSnackbar("Todos los campos son obligatorios", { variant: "error" })
            return;
        }
        await executeHttpRequest({
            endpoint: "auth/login",
            method: "POST",
            body: login,
            onSuccess: async (data) => {
                enqueueSnackbar("Bienvenido", { variant: "success" })
                localStorage.setItem("token", data.Token);
                await getUserInfo();
                history.push("/tarjetas")
            },
            onValidationError: (data) => {
                setLoginValidation(data);
            },
            onError: (data) => {
                if (data.statusCode === 409) {
                    // It means that the account was not verified
                    setShowConfirmationdialog(true);
                }
            }
        });
    }

    const setLoginValues = (e: any) => {
        const { name, value } = e.target;
        setLogin(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    return { login, loginValidation, signIn, setLoginValues, showConfirmationDialog, setShowConfirmationdialog, showResetPasswordDialog, setShowResetPasswordDialog }
}

export default useLogin;