import useHttpRequest from "Hooks/useHttpRequest";
import { Card, CreateEmptyCard } from "Models/Card/Card";
import { CardValidation, CreateEmptyCardValidation } from "Models/Card/CardValidation";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";



const useCardDrawer = () => {
    const { executeHttpRequest } = useHttpRequest();
    const [card, setCard] = useState<Card>(CreateEmptyCard());
    const [groupId, setGroupId] = useState<string>("");
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [cardValidation, setCardValidation] = useState<CardValidation>(CreateEmptyCardValidation());

    const { enqueueSnackbar } = useSnackbar();

    const SetCardValues = (e: any) => {
        const { name, value } = e.target;
        setCard(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const SetCardValuesByName = useCallback((name: any, value: any) => {
        setCard(prevState => ({
            ...prevState,
            [name]: value
        }))
    }, [])

    const CreateCardAsync = useCallback(async (callback: any) => {
        const formData = new FormData();

        formData.append("Name", card.Name);
        formData.append("LastName", card.LastName);
        formData.append("Position", card.Position);
        formData.append("Phone", card.Phone);
        formData.append("WhatsApp", (card.WhatsApp as any));
        formData.append("Email", card.Email);

        if (avatarFile) {
            formData.append("avatar", avatarFile);
        }

        await executeHttpRequest({
            endpoint: `group/${groupId}/card`,
            method: "POST",
            withMultipart: true,
            withToken: true,
            body: formData,
            onSuccess: (data) => {
                enqueueSnackbar("Tarjeta creada exitosamente", { variant: "success" });
                callback()
            },
            onValidationError: (response) => {
                setCardValidation(response)
            }
        })
    }, [avatarFile, card, enqueueSnackbar, executeHttpRequest, groupId])

    const UpdateCardAsync = useCallback(async (callback: any) => {
        const formData = new FormData();

        formData.append("Name", card.Name);
        formData.append("LastName", card.LastName);
        formData.append("Position", card.Position);
        formData.append("Phone", card.Phone);
        formData.append("WhatsApp", (card.WhatsApp as any));
        formData.append("Email", card.Email);

        if (avatarFile) {
            formData.append("avatar", avatarFile);
        }
        await executeHttpRequest({
            endpoint: `group/${groupId}/card/${card.Id}`,
            method: "PUT",
            withMultipart: true,
            withToken: true,
            body: formData,
            onSuccess: (data) => {
                enqueueSnackbar("Tarjeta actualizada correctamente", { variant: "success" });
                callback();
            },
            onValidationError: (response) => {
                setCardValidation(response)
            }
        })
    }, [avatarFile, card, enqueueSnackbar, executeHttpRequest, groupId])

    const ActionButtonClick = useCallback(async (callback: any) => {
        if (card.Id === "") {
            await CreateCardAsync(callback)
        } else {
            await UpdateCardAsync(callback)
        }
    }, [CreateCardAsync, card.Id, UpdateCardAsync])

    return {
        card,
        setCard,
        cardValidation,
        SetCardValues,
        ActionButtonClick,
        setAvatarFile,
        SetCardValuesByName,
        setGroupId
    }

}
export default useCardDrawer;