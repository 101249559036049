import { Button, createStyles, Divider, Drawer, FormControlLabel, Grid, makeStyles, Switch, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Close } from "@material-ui/icons"
import { Card } from 'Models/Card/Card';
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';
import ImageSelector from 'Components/ImageSelector/ImageSelector';
import useCardDrawer from './useCardDrawer';
import CardPreview from '../CardPreview/CardPreview';
import { Style } from 'Models/Styles/Style';

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: "100%"
        },
        drawerPaper: {
            width: "100%"
        }
    })
)

interface IProps {
    visible: boolean;
    closeCallback: () => void;
    card: Card | null;
    groupId: string;
    style: Style
}

export default function CardDrawer(props: IProps) {
    const classes = useStyles();
    const { card, cardValidation, setGroupId, setCard, SetCardValues, ActionButtonClick, setAvatarFile, SetCardValuesByName } = useCardDrawer();

    useEffect(() => {
        if (props.card) {
            setCard(props.card)
        }
        setGroupId(props.groupId)
    }, [props.card, setCard, setGroupId, props.groupId])

    return (

        < div style={{ width: "100wh" }}>
            <Drawer variant="temporary" open={props.visible}
                anchor="left"
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}>
                <Grid container>
                    <Grid item container xs={12} alignItems="center" justify="center">
                        <Grid item container justify="flex-end" md={12} xs={12}>
                            <Button
                                onClick={() => props.closeCallback()}
                                variant="contained"
                                color="primary"
                                startIcon={<Close />}
                                fullWidth>
                                Cerrar
                                    </Button>
                        </Grid>
                        <Grid item container md={12} xs={12} alignItems="center" justify="center" >
                            <h2>{card.Id !== "" ? "Actualizar Tarjeta" : "Crear Tarjeta"}</h2>
                        </Grid >

                    </Grid>

                </Grid>
                <Divider />
                <form noValidate autoComplete="on" onSubmit={(e) => { e.preventDefault(); ActionButtonClick(props.closeCallback); }}>
                    <Grid container alignItems="flex-start">
                        <Grid item container md={6} xs={12} style={{ padding: "2rem" }} direction="row" spacing={3}>
                            <Grid item xs={12}>
                                <h4>Datos del Contacto</h4>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    autoFocus
                                    label="Nombres"
                                    error={inputHasError(cardValidation.Name)}
                                    helperText={createInputErrorMessage(cardValidation.Name)}
                                    variant="outlined"
                                    name="Name"
                                    value={card.Name}
                                    onChange={SetCardValues}
                                    size="small"
                                    fullWidth />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Apellidos"
                                    error={inputHasError(cardValidation.LastName)}
                                    helperText={createInputErrorMessage(cardValidation.LastName)}
                                    variant="outlined"
                                    name="LastName"
                                    value={card.LastName}
                                    onChange={SetCardValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    label="Cargo/Posición"
                                    error={inputHasError(cardValidation.Position)}
                                    helperText={createInputErrorMessage(cardValidation.Position)}
                                    variant="outlined"
                                    name="Position"
                                    value={card.Position}
                                    onChange={SetCardValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    error={inputHasError(cardValidation.Email)}
                                    helperText={createInputErrorMessage(cardValidation.Email)}
                                    name="Email"
                                    value={card.Email}
                                    onChange={SetCardValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    label="Teléfono"
                                    variant="outlined"
                                    error={inputHasError(cardValidation.Phone)}
                                    helperText={createInputErrorMessage(cardValidation.Phone)}
                                    name="Phone"
                                    value={card.Phone}
                                    onChange={SetCardValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControlLabel control={<Switch checked={card.WhatsApp} name="WhatsApp" onChange={(e) => SetCardValuesByName("WhatsApp", e.target.checked)} />}
                                    label="Activar Whatsapp" />
                            </Grid>
                            <Grid item xs={12}>
                                <ImageSelector TextLabel="Avatar" OnSelectedFileCallback={(file, fileUrl) => { setAvatarFile(file); SetCardValuesByName("AvatarUrl", fileUrl); }} Id="Avatar" ShowPreview={false} />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    fullWidth >
                                    {card.Id !== "" ? "Guardar Cambios" : "Crear"}
                                </Button>

                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12} style={{ padding: "2rem" }}>
                            <CardPreview card={card} style={props.style} />
                        </Grid>
                    </Grid>
                </form>


            </Drawer>
        </div >

    )
}
