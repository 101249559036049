export const inputHasError = (error: string[] | undefined) => {
    if (error !== undefined) {
        if (error?.length > 0) {
            return true;
        }
        return false;
    }
    return false;
}

export const createInputErrorMessage = (error: string[] | undefined, message: string = "* Campo Requerido") => {
    if (error && error.length === 0)
        return message;
    if (error !== undefined) {
        return error.join(" - ");
    }
}

