import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    section1: {
        minHeight: '60vh',
        color: 'black',
        background: '#E5E5E5'
    },
    title: {
        color: 'orange',
        marginBottom: '2rem',
        textAlign: 'start',
        width: '100%'
    },
    paragraph: {
        marginBottom: '1rem',
        marginRight: '1rem',
        display: 'block',
        textAlign: 'start',
        width: '100%'
    },
    button: {
        marginRight: '1rem'
    },
    image: {
        display: "block",
        margin: 'auto'
    },
    leftContent: {
        padding: '3.5rem',
        paddingBottom:"0"
    },
    rightContent: {
    }
}));

export default function Section8() {
    const classes = useStyles();

    return (

        <Grid container justify="center" alignItems="center" className={classes.section1}>


            <Grid item container direction="column" md={6} alignItems="flex-start" justify="center" className={classes.leftContent}>

                <Typography variant="h3" className={classes.title}>
                    Serás mas sostenible
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Las tarjetas digitales te ayudan a ser más sostenible en tu organización
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Ayudamos a reducir residuos y la emisión de gases de invernadero entre otros.
                </Typography>
                <Typography variant="h5" className={classes.paragraph}>
                    Apoyamos en el ahorro de recursos y materia prima consumida.
                </Typography>

            </Grid>
            <Grid item md={6} className={classes.rightContent}>
                <img src="/section8.png" alt="" className={classes.image} width="70%" />
            </Grid>


        </Grid>


    );
}

