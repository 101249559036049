import { ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import theme from "./Theme";
import { SnackbarProvider } from 'notistack';
import AppContextProvider from 'Context/AppContext';


const history = createBrowserHistory();
ReactDOM.render(

  <SnackbarProvider>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </Router>
    </ThemeProvider>
  </SnackbarProvider>
  ,
  document.getElementById('root')
);

