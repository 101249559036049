export interface ResetPasswordValidation {
    Password: string[],
    UserEmail: string[],
    VerificationCode: string[]
}

export const CreateEmtpyResetPasswordValidation = () => ({
    Password: [],
    UserEmail: [],
    VerificationCode: []
} as ResetPasswordValidation)