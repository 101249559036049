import { Button, createStyles, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles, OutlinedInput, TextField } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import ImageSelector from 'Components/ImageSelector/ImageSelector';
import { createInputErrorMessage, inputHasError } from 'lib/ValidatorHelper';
import { Style } from 'Models/Styles/Style';
import React, { useEffect, useState } from 'react'
import StylePreview from '../StylePreview/StylePreview';
import useStyleDrawer from './useStyleDrawer';
import { Info } from "@material-ui/icons"
import MapHelpDialog from 'Components/MapHelpDialog';

interface IProps {
    visible: boolean;
    closeCallback: () => void;
    style: Style | null;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: "100%"
        },
        drawerPaper: {
            width: "100%"
        }
    })
)

export default function StyleDrawer(props: IProps) {
    const {
        style,
        setStyle,
        SetStyleValues,
        CreateStyleAsync,
        UpdateStyleAsync,
        styleValidation,
        SetSyleValuesByName,
        SetBannerFile,
        SetIconFile } = useStyleDrawer();
    const classes = useStyles();

    useEffect(() => {
        if (props.style) {
            setStyle(props.style);

        } else {
            setStyle({
                Id: "",
                Address: "",
                BannerUrl: "",
                BusinessPage: "",
                Business: "",
                UserId: "",
                ColorTheme: "#a46565",
                Facebook: "",
                IconColor: "#dae811",
                IconUrl: "",
                Linkedin: "",
                Name: "",
                Twitter: "",
                Video: "",
            })
        }
    }, [props.style, setStyle, SetSyleValuesByName])

    const ButtonClick = async () => {
        if (style.Id === "") {
            await CreateStyleAsync(props.closeCallback)
        } else {
            await UpdateStyleAsync(props.closeCallback)
        }

    }
    const [openMapDialog, setOpenMapDialog] = useState(false);

    return (
        <div style={{ width: "100wh" }}>
            <Drawer variant="temporary" open={props.visible} anchor="left" className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
                <Grid container>
                    <Grid item container xs={12} alignItems="center" justify="center">
                        <Grid item container justify="flex-end" md={12} xs={12}>
                            <Button
                                onClick={() => props.closeCallback()}
                                variant="contained"
                                color="primary"
                                startIcon={<Close />}
                                fullWidth>
                                Cerrar
                            </Button>
                        </Grid>
                        <Grid item container md={12} xs={12} alignItems="center" justify="center" >
                            <h2>{style.Id !== "" ? "Actualizar Estilo" : "Crear Estilo"}</h2>
                        </Grid >

                    </Grid>
                </Grid>
                <Divider />
                <form autoComplete="on" noValidate onSubmit={async (e) => { e.preventDefault(); await ButtonClick(); }}>
                    <Grid container style={{ padding: "2rem" }} alignItems="flex-start" >
                        <Grid item container xs={12} lg={6} spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    label="Nombre del Estilo"
                                    error={inputHasError(styleValidation.Name)}
                                    helperText={createInputErrorMessage(styleValidation.Name)}
                                    variant="outlined"
                                    name="Name"
                                    value={style.Name}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    label="Color del tema"
                                    labelPlacement="start"
                                    control={<input type="color"
                                        style={{ marginLeft: "10px" }}
                                        name="ColorTheme"
                                        value={style.ColorTheme}
                                        onChange={SetStyleValues} />} />


                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label="Color de los íconos"
                                    labelPlacement="start"
                                    control={<input type="color"
                                        style={{ marginLeft: "10px" }}
                                        name="IconColor"
                                        value={style.IconColor}
                                        onChange={SetStyleValues} />} />
                            </Grid>
                            <Grid item container xs={12} md={6}>
                                <ImageSelector TextLabel="Logotipo"
                                    OnSelectedFileCallback={(file, fileUrl) => { SetSyleValuesByName("IconUrl", fileUrl); SetIconFile(file) }}
                                    Id="Logotipo"
                                    ShowPreview={false} />
                            </Grid>
                            <Grid item container xs={12} md={6}>
                                <ImageSelector TextLabel="Banner"
                                    OnSelectedFileCallback={(file, fileUrl) => { SetSyleValuesByName("BannerUrl", fileUrl); SetBannerFile(file) }}
                                    Id="Banner" ShowPreview={false} />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Empresa"
                                    error={inputHasError(styleValidation.Business)}
                                    helperText={createInputErrorMessage(styleValidation.Business)}
                                    variant="outlined"
                                    name="Business"
                                    value={style.Business}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Pagina web de la empresa (ej. https://trovamibo.com)"
                                    error={inputHasError(styleValidation.BusinessPage)}
                                    helperText={createInputErrorMessage(styleValidation.BusinessPage)}
                                    variant="outlined"
                                    name="BusinessPage"
                                    value={style.BusinessPage}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    label="Link página Twitter (ej. https://twitter.com/profile)"
                                    error={inputHasError(styleValidation.Twitter)}
                                    helperText={createInputErrorMessage(styleValidation.Twitter, "")}
                                    variant="outlined"
                                    name="Twitter"
                                    value={style.Twitter}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    label="Link página Facebook (ej. https://facebook.com/profile)"
                                    error={inputHasError(styleValidation.Facebook)}
                                    helperText={createInputErrorMessage(styleValidation.Facebook, "")}
                                    variant="outlined"
                                    name="Facebook"
                                    value={style.Facebook}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />

                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    label="Link página Linkedin (ej. https://linkedin.com/profile)"
                                    error={inputHasError(styleValidation.Linkedin)}
                                    helperText={createInputErrorMessage(styleValidation.Linkedin, "")}
                                    variant="outlined"
                                    name="Linkedin"
                                    value={style.Linkedin}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />

                            </Grid>

                            <Grid item md={12} xs={12}>

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="direccion">Dirección</InputLabel>
                                    <OutlinedInput id="direccion"
                                        labelWidth={70}
                                        type="text"
                                        value={style.Address}
                                        name="Address"
                                        onChange={SetStyleValues}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setOpenMapDialog(true)}>
                                                    <Info />
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                </FormControl>

                            </Grid>

                            <Grid item md={12} xs={12}>
                                <TextField
                                    label="Link Video de la Empresa"
                                    error={inputHasError(styleValidation.Video)}
                                    helperText={createInputErrorMessage(styleValidation.Video, "")}
                                    variant="outlined"
                                    name="Video"
                                    value={style.Video}
                                    onChange={SetStyleValues}
                                    size="small"
                                    fullWidth />

                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    fullWidth >
                                    {style.Id !== "" ? "Guardar Cambios" : "Crear"}
                                </Button>

                            </Grid>
                        </Grid>


                        <Grid item container xs={12} lg={6} style={{ marginLeft: "15px" }} >
                            <StylePreview style={style} />
                        </Grid>
                    </Grid>
                </form>
                {openMapDialog && <MapHelpDialog Open={openMapDialog} OnCloseCallback={() => setOpenMapDialog(false)} />}
            </Drawer>
        </div >
    )
}
