import MainLayout from 'Components/MainLayout'
import React from 'react'
import StylesContent from './StylesContent'



export default function StylePage() {

    return (
        <div>
            <MainLayout mainContent={<StylesContent />} titleAppBar="Estilos" />

        </div>
    )
}
