import React, { useState } from 'react'
import useCardContent from './useCardContent';
import { DataGrid, GridColDef, GridRowsProp } from '@material-ui/data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { Delete, PersonAdd, Update } from "@material-ui/icons"
import CardDrawer from '../CardDrawer/CardDrawer';
import moment from "moment";

export default function CardsContent() {
    const { cards, selectedCard, selectCard, groupId, deleteCardAsync, openDeleteDialog, setOpenDeleteDialog, getCardsAsync, redirectToCard, style } = useCardContent();
    const [openDrawer, setOpenDrawer] = useState(false);


    const columns: GridColDef[] = [
        { field: "name", headerName: "Nombres", flex: 1 },
        { field: "lastName", headerName: "Apellido", flex: 1 },
        { field: "position", headerName: "Cargo", flex: 1 },
        { field: "business", headerName: "Empresa", flex: 1 },
        { field: "expDate", headerName: "Válido hasta", flex: 1 },
    ]

    const rows: GridRowsProp = cards.map(card => {
        return {
            id: card.Id,
            name: card.Name,
            lastName: card.LastName,
            position: card.Position,
            business: card.Business,
            expDate: moment(card.ExpDate).format("DD/MM/YYYY")
        }
    })


    return (
        <div>

            <Grid container spacing={3}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDrawer(true)} color="secondary" startIcon={<PersonAdd />} disabled={selectedCard !== null} fullWidth>Agregar</Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDrawer(true)} color="primary" startIcon={<Update />} disabled={selectedCard == null ? true : false} fullWidth>Modificar</Button >

                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button variant="contained" onClick={() => setOpenDeleteDialog(true)} color="default" startIcon={<Delete />} disabled={selectedCard == null ? true : false} fullWidth>Eliminar </Button>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Button onClick={() => { selectCard("") }}
                            variant="contained" color="primary" startIcon={<Update />} disabled={selectedCard == null ? true : false} fullWidth>Deseleccionar</Button >

                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Button onClick={() => { redirectToCard() }}
                            variant="contained" color="primary" startIcon={<Update />} disabled={selectedCard == null ? true : false} fullWidth>Ver Tarjeta</Button >

                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ height: "50vh" }}>

                    <DataGrid columns={columns}
                        rows={rows} disableColumnMenu hideFooterSelectedRowCount
                        onRowSelected={(data) => { selectCard(data.data.id.toString()) }} scrollbarSize={12}
                    />

                </Grid>
            </Grid>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} fullWidth>
                <DialogTitle>Borrar Tarjeta de {selectedCard?.Name} {selectedCard?.LastName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta seguro que desea eliminar esta tarjeta?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={() => { deleteCardAsync() }}>
                        Eliminar Tarjeta
                    </Button>
                </DialogActions>
            </Dialog>

            {openDrawer && <CardDrawer style={style} visible={openDrawer} closeCallback={async () => { setOpenDrawer(false); await getCardsAsync(); selectCard("") }} card={selectedCard} groupId={groupId} />}

        </div>


    )
}
