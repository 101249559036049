import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import useHttpRequest from 'Hooks/useHttpRequest';
import { Suscritpion } from 'Models/Suscriptions/Suscription'
import React, { useCallback, useEffect, useState } from 'react'
import moment from "moment";
import AddSuscriptionDialog from './AddSuscriptionDialog';
import { useSnackbar } from 'notistack';

function SuscriptionsContent() {
    const { executeHttpRequest } = useHttpRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [suscriptions, setSuscriptions] = useState<Suscritpion[]>([]);
    const [openDialog, setOpenDialog] = useState(false);

    const GetSuscriptions = useCallback(async () => {
        await executeHttpRequest({
            endpoint: "suscription",
            method: "GET",
            withToken: true,
            onSuccess: (suscriptions) => {
                setSuscriptions(suscriptions);
            }
        })
    }, [executeHttpRequest])

    const setSuscriptionStatus = (suscription: Suscritpion) => {
        const initialDate = moment().utc().add(-4, "hours")
        const endingDate = moment(suscription.EndingDate).utc().add(-4, "hours")
        const suscriptionExpired = initialDate.isAfter(endingDate);
        if (suscriptionExpired) {
            return "Expirado"
        } else if (suscription.Active) {
            return "Activo"
        } else {
            return "Pendiente"
        }
    }

    const RenewSuscription = useCallback(async (suscriptionId) => {
        await executeHttpRequest({
            endpoint: `suscription/${suscriptionId}`,
            method: "PATCH",
            withToken: true,
            onSuccess: async () => {
                enqueueSnackbar("Renovación solicitada una administrador lo revisará pronto", { variant: "success" });
                await GetSuscriptions()
            }
        })
    }, [GetSuscriptions, executeHttpRequest, enqueueSnackbar])

    useEffect(() => {
        GetSuscriptions()
    }, [GetSuscriptions])

    return (
        <div style={{ height: "50vh" }}>
            <Button variant="outlined" onClick={() => setOpenDialog(true)}>Solicitar Suscripción</Button>
            <h5>Para consultas comunicarse mediante Whatsapp al número +591 67196247</h5>
            <TableContainer component={Paper}>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha de Inicio</TableCell>
                            <TableCell>Fecha de Finalización</TableCell>
                            <TableCell>Nº Tarjetas Solicitadas</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Renovar Suscripción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {suscriptions.map(s => (
                            <TableRow key={s.Id}>
                                <TableCell>{moment().format("DD/MM/YYYY")}</TableCell>
                                <TableCell>{moment(s.EndingDate).format("DD/MM/YYYY")}</TableCell>
                                <TableCell>{s.NumberCards}</TableCell>
                                <TableCell>{setSuscriptionStatus(s)}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => RenewSuscription(s.Id)}
                                        color="primary" variant="outlined" disabled={setSuscriptionStatus(s) !== "Expirado"}>Solicitar Renovación</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddSuscriptionDialog OnCloseCallback={() => setOpenDialog(false)}
                OnActionCallback={async () => { await GetSuscriptions(); setOpenDialog(false) }}
                Open={openDialog} />
        </div>
    )
}

export default SuscriptionsContent
