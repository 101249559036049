import MainLayout from 'Components/MainLayout'
import DashBoardContent from 'Pages/DashBoard/DashBoardContent'
import React from 'react'

export default function DashBoard() {

    return (
        <div>
            <MainLayout mainContent={<DashBoardContent />} titleAppBar="Trovami Bolivia" />
        </div>
    )
}
