import MainLayoutAdmin from 'Components/MainLayoutAdmin'
import React from 'react'
import AdminMessagesContent from './AdminMessagesContent'

function AdminMessagesPage() {
    return (
        <div>
            <MainLayoutAdmin mainContent={<AdminMessagesContent />} titleAppBar="Mensajes" />
        </div>
    )
}

export default AdminMessagesPage
