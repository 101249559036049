import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: '1rem',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

const tiers = [
    {
        title: 'Personal',
        price: '69,97',
        help: "",
        description: [
            { description: 'Autogestión de tarjetas', note: "Sistema sencillo y rapido para crear las tarjetas que necesites." },
            { description: 'Diseños multidispositivo', note: "Todos los diseños disponibles permiten el acceso desde el móvil, tablet y PC." },
            { description: 'Opciones de personalización', note: "Añade el logo de tu empresa, foto de perfil, color, íconos de portadas. Dale la identidad que gustes" },
            { description: 'Información de contacto', note: "Datos de contacto y puesto hasta en 3 niveles." },
            { description: 'Acceso directo a tus redes sociales' },
            { description: 'Integración con tarjeta impres y perfil de LinkedIn', note: "Se genera un código QR mixto para integrar con la tarjeta impresa y te ayudamos a integrar tu tarjeta en el perfil de LinkedIn." },
            { description: 'No requiere App' },
            { description: 'Acceso a un video', note: "Integra videos para promocionar tus servicios, productos o lo que prefieras y compartelo con tus contactos." },
            { description: 'Botón personalizado CTA', note: "Lleva a  tus contactos a la página que necesites a través de un boton de llamada a la acción." },
            { description: 'Localización en el mapa', note: "Facilita que tus contactos llegue a tí mostrando a tus contactos tu localización" }
        ]
    },
    {
        title: 'Empresarial',
        price: '89,97',
        help: "Caracteŕisticas del pack Personal más:",
        description: [
            { description: 'Capacidad de más grupos para organizar todas tu tarjetas', note: '' },
            { description: 'Capacidad de mas diseños', note: '' }
        ]
    }

];

export default function PricePage() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Precios
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main" style={{ marginBottom: "2rem" }}>
                <Grid container spacing={2} alignItems="flex-start" justify="space-between">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <div className={classes.cardPricing}>
                                        <Typography component="h2" variant="h3" color="textPrimary">
                                            {tier.price} Bs.
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            /año
                                        </Typography>
                                    </div>
                                    {tier.title === 'Empresarial' && <h2 style={{ textAlign: "center" }}>{tier.help}</h2>}
                                    {tier.title === "Empresarial" && <Divider />}
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography component="li" variant="subtitle1" align="left" key={line.description}>
                                                <b>{line.description}</b>
                                                <Typography component="p" variant="inherit" align="left" key={line.description}>
                                                    {line.note}
                                                </Typography>
                                            </Typography>

                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Footer />
        </React.Fragment >
    );
}