import MainLayout from 'Components/MainLayout'
import React from 'react'
import AccountContent from './AccountContent'

export default function AccountPage() {

    return (
        <div>
            <MainLayout mainContent={<AccountContent />} titleAppBar="Cuenta" />
        </div>
    )
}
