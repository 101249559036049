import Footer from "Components/Footer";
import Header from "Components/Header";
import Section1 from "Components/Section1";
import Section2 from "Components/Section2";
import Section3 from "Components/Section3";
import Section4 from "Components/Section4";
import Section5 from "Components/Section5";
import Section6 from "Components/Section6";
import Section8 from "Components/Section8";
import SignInPage from "Pages/SignUp/SignUpPage";
import LoginPage from "Pages/Login/LoginPage";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "./app.css";
import DashBoard from "Pages/DashBoard/DashBoard";
import GroupsPage from "Pages/Group/GroupsPage";
import { useAppState } from "Context/AppContext";
import Loading from "Components/Loading";
import CardsPage from "Pages/Cards/CardPage/CardsPage";
import CardPublic from "Pages/Cards/CardPublic/CardPublic";
import StylePage from "Pages/Styles/StylePage";
import ContactPage from "Pages/Contact/ContactPage";
import AdminLoginPage from "Pages/Admin/Login/AdminLogin";
import AdminMessagesPage from "Pages/Admin/Messages/AdminMessagesPage";
import AdminSuscriptionPage from "Pages/Admin/Suscriptions/AdminSuscriptionPage";
import SuscriptionsPage from "Pages/Account/SuscriptionsPage";
import AccountPage from "Pages/Account/AccountPage/AccountPage";
import HelpPage from "Pages/Help/HelpPage";
import PricePage from "Pages/Price/PricePage";

function App() {

  const { loading } = useAppState();



  return (
    <div className="App">
      {loading && <Loading open={loading} />}
      <Switch>
        <Route path="/" exact>
          <Header />
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section8 />
          <Footer />
        </Route>
        <Route path="/SignIn" exact>
          <SignInPage />
        </Route>
        <Route path="/Login" exact>
          <LoginPage />
        </Route>
        <Route path="/DashBoard" exact>
          <DashBoard />
        </Route>
        <Route path="/Tarjetas" exact>
          <GroupsPage />
        </Route>
        <Route path="/Estilos" exact>
          <StylePage />
        </Route>
        <Route path="/Grupo/:groupId/tarjetas" exact>
          <CardsPage />
        </Route>
        <Route path="/tarjeta/:cardId" exact>
          <CardPublic />
        </Route>
        <Route path="/contacto" exact>
          <ContactPage />
        </Route>
        <Route path="/login/admin" exact>
          <AdminLoginPage />
        </Route>
        <Route path="/admin/mensajes" exact>
          <AdminMessagesPage />
        </Route>
        <Route path="/admin/suscripciones" exact>
          <AdminSuscriptionPage />
        </Route>
        <Route path="/suscripciones" exact>
          <SuscriptionsPage />
        </Route>
        <Route path="/cuenta" exact>
          <AccountPage />
        </Route>
        <Route path="/ayuda" exact>
          <HelpPage />
        </Route>
        <Route path="/precios" exact>
          <PricePage />
        </Route>
      </Switch>


    </div>
  );
}

export default App;
